import {
  type Editor,
  type NodeViewProps,
  NodeViewWrapper,
} from '@tiptap/react';
import { useCallback, useRef } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { QuestionMarkIcon } from '../../icons/QuestionMarkIcon';
import { type MarkOptions } from './DialogueEditorMark';
import { type DialogueMarkerTutorQuestion } from './types';

export function TutorQuestionModal(props: {
  value: Pick<DialogueMarkerTutorQuestion, 'question' | 'finishCriteria'>;
  onCancel: () => void;
  onSave: (
    next: Pick<DialogueMarkerTutorQuestion, 'question' | 'finishCriteria'>
  ) => void;
}) {
  const { value, onCancel, onSave } = props;
  const questionRef = useRef<HTMLInputElement | null>(null);
  const criteriaRef = useRef<HTMLTextAreaElement | null>(null);
  const handleSave = useLiveCallback(() => {
    onSave({
      ...value,
      question: questionRef.current?.value ?? '',
      finishCriteria: criteriaRef.current?.value ?? '',
    });
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-180 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-lg'>Tutor Question</div>
            <div className='pt-1 text-sm text-icon-gray'>
              The tutor will proactively ask the learner this question
            </div>
          </div>

          <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
            <input
              ref={questionRef}
              className={`w-full h-12.5 field m-0`}
              placeholder={'Enter tutor question...'}
              defaultValue={props.value.question}
              autoFocus
            />
          </div>
        </div>
        <div>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-lg'>Finish Criteria (optional)</div>
            <div className='pt-1 text-sm text-icon-gray'>
              When should the tutor finish the session?
            </div>
          </div>

          <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
            <textarea
              ref={criteriaRef}
              className={`w-full h-20 field m-0 py-3`}
              placeholder={
                '(e.g. “The learner has shared an example of how they use math in their daily life.”)'
              }
              defaultValue={props.value.finishCriteria}
            />
          </div>
        </div>

        <div className='mt-auto w-full flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export function useTriggerTutorQuestionEditor() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    async (props: {
      value: Pick<DialogueMarkerTutorQuestion, 'question' | 'finishCriteria'>;
      onSave?: (
        next: Pick<DialogueMarkerTutorQuestion, 'question' | 'finishCriteria'>
      ) => void;
      onCancel?: () => void;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <TutorQuestionModal
            value={props.value}
            onCancel={() => {
              props.onCancel?.();
              p.internalOnCancel();
            }}
            onSave={(next) => {
              props.onSave?.(next);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}

export function DialogueEditorMarkTutorQuestion(props: NodeViewProps) {
  const { editor } = props;
  const ext = props.extension as { options: MarkOptions };
  const { name, question, finishCriteria } = props.node.attrs;
  const mark: DialogueMarkerTutorQuestion = {
    type: 'tutor-question',
    name,
    question,
    finishCriteria,
  };

  const triggerTutorQuestionEditor = useTriggerTutorQuestionEditor();

  const handleClick = () => {
    triggerTutorQuestionEditor({
      value: mark,
      onSave: (next) => {
        props.updateAttributes({
          question: next.question,
          finishCriteria: next.finishCriteria,
        });
        ext.options.onChange(editor);
      },
    });
  };

  return (
    <NodeViewWrapper
      className='inline-block'
      type='tutor-question'
      name={name}
      draggable
      data-drag-handle=''
    >
      <div
        className='text-sm text-gray-500 cursor-pointer'
        onClick={() => handleClick()}
      >
        [tutor: {question}]
      </div>
    </NodeViewWrapper>
  );
}

export function AddTutorQuestionMarkButton(props: { editor: Editor }) {
  const { editor } = props;

  const triggerTutorQuestionEditor = useTriggerTutorQuestionEditor();

  const handleClick = () => {
    triggerTutorQuestionEditor({
      value: { question: '', finishCriteria: '' },
      onSave: (next) => {
        editor
          .chain()
          .focus()
          .insertContent({
            type: 'mark',
            attrs: {
              type: 'tutor-question',
              name: uuidv4(),
              question: next.question,
              finishCriteria: next.finishCriteria,
            },
          })
          .focus()
          .run();
      },
      onCancel: () => {
        editor.chain().focus().run();
      },
    });
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className='text-icon-gray hover:text-white'
      title='Add Tutor Question'
    >
      <QuestionMarkIcon className='w-4 h-4 fill-current' />
    </button>
  );
}
