import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { TrainingEditor } from '../components/Training/Editor/TrainingEditor';
import { useUser } from '../components/UserContext';
import { getFeatureQueryParamArrayWith } from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { RoleUtils } from '../types';
import { booleanify } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(args: ClientLoaderFunctionArgs) {
  const { id } = args.params;
  if (!id) {
    throw new Error('No id provided');
  }

  const url = new URL(args.request.url);
  const showPreviewTooltip = booleanify(
    url.searchParams.get('preview-tooltip')
  );
  const agentMode = getFeatureQueryParamArrayWith('agentic', url.search);

  const resp = await tokenWithRedirect(
    async () =>
      await apiService.gamePack.getGamePackById(id, {
        blocks: true,
        games: true,
        linkedSharedAssets: true,
      }),
    args.request.url
  );

  return {
    ...resp.data,
    showPreviewTooltip,
    agentMode,
  };
}

export function Component() {
  const { gamePack, games, blocks, showPreviewTooltip, agentMode } =
    useLoaderData<typeof clientLoader>();

  const providers = [<ConfirmCancelModalProvider />];

  const user = useUser();

  if (!RoleUtils.isAdmin(user) && gamePack.uid !== user.id) {
    return (
      <div className='text-white w-screen h-screen flex flex-col justify-center items-center gap-4 text-center'>
        You are not authorized to edit this training. <br />
        Please make sure you are logged in with the correct account.
      </div>
    );
  }

  return (
    <ProvidersList providers={providers}>
      <TrainingEditor
        pack={gamePack}
        games={games || []}
        blocks={blocks || []}
        showPreviewTooltip={showPreviewTooltip}
        agentMode={agentMode}
        showShareButton={RoleUtils.isAdmin(user)}
      />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
