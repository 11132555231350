import { type Editor, type KeyboardShortcutCommand, Node } from '@tiptap/core';
import { type NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';

import {
  DialogueEditorMarkImage,
  dialogueEditorMarkImagePlugins,
} from './DialogueEditorMarkImage';
import {
  DialogueEditorMarkTrigger,
  dialogueEditorMarkTriggerShortcuts,
} from './DialogueEditorMarkTrigger';
import { DialogueEditorMarkTutorQuestion } from './DialogueEditorMarkTutorQuestion';
import { type DialogueMarkerType } from './types';

export interface MarkOptions {
  enabledTypes: DialogueMarkerType[];
  onChange: (editor: Nullable<Editor>) => void;
}

export const DialogueEditorMark = Node.create<MarkOptions>({
  name: 'mark',
  inline: true,
  group: 'inline',
  draggable: true,
  atom: true,

  addOptions() {
    return {
      enabledTypes: ['trigger', 'image', 'tutor-question'],
      onChange: () => {
        // just a placeholder
      },
    };
  },

  addAttributes() {
    return {
      type: {
        default: 'trigger',
      },
      name: {
        default: '',
      },
      keywords: {
        default: '',
      },
      query: {
        default: '',
      },
      question: {
        default: '',
      },
      finishCriteria: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark',
      },
    ];
  },

  renderHTML({ node }) {
    return [
      'mark',
      {
        type: node.attrs.type,
        name: node.attrs.name,
        keywords: node.attrs.keywords,
        query: node.attrs.query,
        question: node.attrs.question,
        finishCriteria: node.attrs.finishCriteria,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MarkComponent);
  },

  addProseMirrorPlugins() {
    const enabledTypes = this.options.enabledTypes;

    const plugins = [];
    if (enabledTypes.includes('image')) {
      plugins.push(...dialogueEditorMarkImagePlugins);
    }

    return plugins;
  },

  addKeyboardShortcuts() {
    const enabledTypes = this.options.enabledTypes;
    const shortcuts: Record<string, KeyboardShortcutCommand> = {};

    if (enabledTypes.includes('trigger')) {
      Object.assign(shortcuts, dialogueEditorMarkTriggerShortcuts(this.editor));
    }

    return shortcuts;
  },
});

function MarkComponent(props: NodeViewProps) {
  const type = props.node.attrs.type as DialogueMarkerType;

  switch (type) {
    case 'trigger':
      return <DialogueEditorMarkTrigger {...props} />;
    case 'image':
      return <DialogueEditorMarkImage {...props} />;
    case 'tutor-question':
      return <DialogueEditorMarkTutorQuestion {...props} />;
    default:
      console.log('type', type);
      // assertExhaustive(type);
      return null;
  }
}
