import { type ReactNode } from 'react';
import { useSnapshot } from 'valtio';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';
import {
  type Block,
  BlockType,
  type SlideBlock,
  type SlideBlockAnimationKey,
} from '@lp-lib/game';

import { type useLearningAnalytics } from '../../../analytics/learning';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type Game } from '../../../types/game';
import { BlockEditor } from '../../Game/Blocks/Common/Editor';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { type BlockAnimator } from '../../GameV2/apis/BlockAnimationControl';
import { StatusBar } from '../../GameV2/design/StatusBar';
import { Loading } from '../../Loading';
import { BlockEditorStoreProvider } from '../../RoutedBlock';
import {
  DrawToWinBlockEditor,
  DrawToWinBlockSidebarEditor,
} from './DrawToWinBlockEditor';
import {
  FillInTheBlanksBlockEditor,
  FillInTheBlanksBlockEditorUnder,
  FillInTheBlanksBlockSidebarEditor,
} from './FillInTheBlanksBlockEditor';
import {
  HiddenPictureBlockEditor,
  HiddenPictureBlockSidebarEditor,
} from './HiddenPictureBlockEditor';
import {
  JeopardyBlockEditor,
  JeopardyBlockEditorSidebar,
  JeopardyBlockEditorTabs,
} from './JeopardyBlockEditor';
import {
  MatchBlockEditor,
  MatchBlockEditorUnder,
  MatchBlockSidebarEditor,
} from './MatchBlockEditor';
import {
  MultipleChoiceBlockEditor,
  MultipleChoiceBlockEditorUnder,
  MultipleChoiceBlockSidebarEditor,
} from './MultipleChoiceBlockEditor';
import {
  QuestionBlockEditor,
  QuestionBlockEditorUnder,
  QuestionBlockSidebarEditor,
} from './QuestionBlockEditor';
import {
  ResultsBlockEditor,
  ResultsBlockSidebarEditor,
} from './ResultsBlockEditor';
import {
  RoleplayBlockEditor,
  RoleplayBlockEditorTabs,
  RoleplayBlockSidebarEditor,
} from './RoleplayBlockEditor';
import {
  ScenarioBlockEditor,
  ScenarioBlockEditorTabs,
  ScenarioBlockSidebarEditor,
} from './ScenarioBlockEditor';
import {
  SlideBlockEditor,
  SlideBlockSidebarEditor,
  SlidesBlockEditorUnder,
} from './SlideBlockEditor';
import { SlideGroupEditor } from './SlideGroupEditor';
import {
  SparkifactBlockEditor,
  SparkifactBlockSidebarEditor,
} from './SparkifactBlockEditor';
import {
  SwipeToWinBlockEditor,
  SwipeToWinBlockEditorUnder,
  SwipeToWinBlockSidebarEditor,
} from './SwipeToWinBlockEditor';
import { AIChatButton } from './TrainingEditorAIChat';
import { type TrainingEditorControlAPI } from './TrainingEditorControlAPI';
import { type TrainingEditorState } from './types';

function BlockDetailTabs(props: { block: Block }) {
  const { block } = props;

  switch (block?.type) {
    case BlockType.ROLEPLAY:
      return <RoleplayBlockEditorTabs key={block.id} />;
    case BlockType.JEOPARDY:
      return <JeopardyBlockEditorTabs key={block.id} />;
    case BlockType.SCENARIO:
      return <ScenarioBlockEditorTabs key={block.id} />;
    default:
      return null;
  }
}

function BlockDetailCenter(props: {
  store: GameEditorStore;
  block: Block;
  progressPct: number;
  animator: BlockAnimator;
  analytics: ReturnType<typeof useLearningAnalytics>;
  ctrl: TrainingEditorControlAPI;
}) {
  const { store, block, progressPct, animator, analytics } = props;

  return (
    <div
      className={`
        relative
        bg-black text-white
        border border-secondary rounded-lg
        w-full z-5 flex-none overflow-hidden
  `}
      style={{
        aspectRatio: block ? '8 / 5' : 'auto',
      }}
    >
      <BlockEditorStoreProvider store={store.blockEditorStore}>
        <div className='flex-1 h-full overflow-auto scrollbar'>
          <BlockEditorLayout progressPct={progressPct}>
            <BlockEditorDetail
              gameId={store.state.game?.id ?? ''}
              block={block}
              animator={animator}
              analytics={analytics}
              ctrl={props.ctrl}
            />
          </BlockEditorLayout>
        </div>
      </BlockEditorStoreProvider>
    </div>
  );
}

export function BlockDetailUnder(props: {
  block: Block;
  store: GameEditorStore;
  analytics: ReturnType<typeof useLearningAnalytics>;
}) {
  const { block, store, analytics } = props;

  const setSavingChanges = useLiveCallback(() => console.log('saving changes'));

  let children = null;
  switch (block?.type) {
    case BlockType.SLIDE:
      children = (
        <SlidesBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
    case BlockType.QUESTION:
      children = (
        <QuestionBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
    case BlockType.MULTIPLE_CHOICE:
      children = (
        <MultipleChoiceBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
    case BlockType.SWIPE_TO_WIN:
      children = (
        <SwipeToWinBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
    case BlockType.MEMORY_MATCH:
      children = (
        <MatchBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
    case BlockType.FILL_IN_THE_BLANKS:
      children = (
        <FillInTheBlanksBlockEditorUnder
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
      break;
  }

  return (
    children && (
      <div className='w-full'>
        <BlockEditorStoreProvider store={store.blockEditorStore}>
          {children}
        </BlockEditorStoreProvider>
      </div>
    )
  );
}

function BlockEditorDetail(props: {
  gameId: string;
  block: Block;
  animator: BlockAnimator;
  analytics: ReturnType<typeof useLearningAnalytics>;
  ctrl: TrainingEditorControlAPI;
}) {
  const { block, animator, analytics } = props;

  const setSavingChanges = useLiveCallback(() => console.log('saving changes'));

  switch (block.type) {
    case BlockType.SLIDE:
      return (
        <SlideBlockEditor
          key={block.id}
          block={block as SlideBlock}
          setSavingChanges={setSavingChanges}
          animator={animator as BlockAnimator<SlideBlockAnimationKey>}
          analytics={analytics}
        />
      );
    case BlockType.QUESTION:
      return (
        <QuestionBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.MEMORY_MATCH:
      return (
        <MatchBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.ROLEPLAY:
      return (
        <RoleplayBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.DRAW_TO_WIN:
      return (
        <DrawToWinBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SPARKIFACT:
      return (
        <SparkifactBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.FILL_IN_THE_BLANKS:
      return (
        <FillInTheBlanksBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SWIPE_TO_WIN:
      return (
        <SwipeToWinBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SCENARIO:
      return (
        <ScenarioBlockEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.RESULTS:
      return (
        <ResultsBlockEditor
          key={block.id}
          gameId={props.gameId}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
          ctrl={props.ctrl}
        />
      );
    default:
      return (
        <div className='w-full h-full px-5'>
          <BlockEditor block={block} setSavingChanges={setSavingChanges} />
        </div>
      );
  }
}

function BlockSidebar(props: {
  block: Block;
  animator: BlockAnimator;
  analytics: ReturnType<typeof useLearningAnalytics>;
}) {
  const { block, analytics } = props;

  const setSavingChanges = useLiveCallback(() => console.log('saving changes'));
  switch (block.type) {
    case BlockType.SLIDE:
      return (
        <SlideBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
          animator={props.animator}
        />
      );
    case BlockType.QUESTION:
      return (
        <QuestionBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.MEMORY_MATCH:
      return (
        <MatchBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.ROLEPLAY:
      return (
        <RoleplayBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.DRAW_TO_WIN:
      return (
        <DrawToWinBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockEditorSidebar
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SPARKIFACT:
      return (
        <SparkifactBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.FILL_IN_THE_BLANKS:
      return (
        <FillInTheBlanksBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SWIPE_TO_WIN:
      return (
        <SwipeToWinBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.SCENARIO:
      return (
        <ScenarioBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    case BlockType.RESULTS:
      return (
        <ResultsBlockSidebarEditor
          key={block.id}
          block={block}
          setSavingChanges={setSavingChanges}
          analytics={analytics}
        />
      );
    default:
      return null;
  }
}

export function TrainingEditorDetailSidebar(props: {
  store: GameEditorStore;
  animator: BlockAnimator;
  analytics: ReturnType<typeof useLearningAnalytics>;
}) {
  const { store, animator, analytics } = props;
  const { selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const selectedBlock = blocks.find((b) => b.id === selectedBlockId);

  if (!selectedBlock) return null;

  return (
    <BlockEditorStoreProvider store={store.blockEditorStore}>
      <BlockSidebar
        block={selectedBlock as Block}
        animator={animator}
        analytics={analytics}
      />
    </BlockEditorStoreProvider>
  );
}

function BlockEditorLayout(props: {
  progressPct: number;
  children: React.ReactNode;
}) {
  return (
    <div className='relative w-full h-full'>
      <div className='w-full max-w-240 h-full min-h-0 mx-auto flex flex-col'>
        <div className='p-5 pb-0 z-5'>
          <StatusBar progressPct={props.progressPct} />
        </div>
        <div className='flex-1 min-h-0 overflow-hidden'>{props.children}</div>
      </div>
    </div>
  );
}

export function TrainingEditorDetails(props: {
  pack: DtoGamePack;
  stores: GameEditorStore[];
  store: GameEditorStore;
  animator: BlockAnimator;
  analytics: ReturnType<typeof useLearningAnalytics>;
  state: TrainingEditorState;
  ctrl: TrainingEditorControlAPI;
  aiChatButtonEnabled?: boolean;
  sidebar?: ReactNode;
}) {
  const { pack, stores, store, animator, analytics, state, ctrl } = props;
  const { game, selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const selectedBlock =
    state.previewBlock || blocks.find((b) => b.id === selectedBlockId);
  const selectedBlockIndex = blocks.findIndex((b) => b.id === selectedBlockId);
  const progressPct =
    selectedBlockIndex === -1 ? 0 : (selectedBlockIndex / blocks.length) * 100;

  return (
    <div
      className='relative w-full h-full flex gap-4'
      key={state.blockDirtyKey}
    >
      <div className='flex-1 min-w-98 h-full'>
        {selectedBlock ? (
          <div className='w-full h-full flex flex-col items-center gap-2 overflow-auto scrollbar'>
            <BlockDetailTabs block={selectedBlock as Block} />
            <BlockDetailCenter
              store={store}
              block={selectedBlock as Block}
              progressPct={progressPct}
              animator={animator}
              analytics={analytics}
              ctrl={ctrl}
            />
            <BlockDetailUnder
              block={selectedBlock as Block}
              store={store}
              analytics={analytics}
            />
            {props.aiChatButtonEnabled && (
              <AIChatButton
                aiChatStatus={state.aiChatStatus}
                selectedBlock={selectedBlock as Block}
                ctrl={ctrl}
              />
            )}
            {state.previewBlock && (
              <div className='z-50 flex items-center justify-center gap-8 p-3 rounded-lg border border-secondary animate-fade-in'>
                <p className='text-white text-sm'>
                  You are viewing a historical version <br />
                  You need to restore this version to edit
                </p>
                <div className='flex gap-3 text-sm'>
                  <button
                    type='button'
                    className='px-4 py-2 btn-secondary'
                    onClick={() => {
                      if (!selectedBlock) return;

                      store.blockEditorStore.replaceBlock(
                        selectedBlock as Block
                      );
                      apiService.block.updateBlockFields(
                        selectedBlock.id,
                        selectedBlock.fields
                      );
                      ctrl.clearPreviewBlock();
                      ctrl.markCurrentBlockDirty();
                    }}
                  >
                    Restore this version
                  </button>
                  <button
                    type='button'
                    className='px-4 py-2 btn-secondary'
                    onClick={() => {
                      ctrl.clearPreviewBlock();
                      ctrl.markCurrentBlockDirty();
                    }}
                  >
                    Back to latest version
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : game ? (
          <div
            className={`
              relative
              bg-black text-white
              border border-secondary rounded-lg
              overflow-auto scrollbar
              w-full z-5
              flex-grow
            `}
          >
            <SlideGroupEditor
              pack={pack}
              stores={stores}
              selectedGame={game as Game}
            />
          </div>
        ) : null}
      </div>

      {props.sidebar}

      {state.aiChatMessageSending && (
        <div className='absolute z-5 inset-0 rounded-lg bg-lp-black-004 flex items-center justify-center'>
          <Loading text='' />
        </div>
      )}

      {state.previewBlock && (
        <div className='absolute z-5 inset-0 rounded-lg cursor-not-allowed bg-lp-black-002'></div>
      )}
    </div>
  );
}
