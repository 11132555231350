import { type Editor } from '@tiptap/react';
import { useCallback, useRef, useState } from 'react';

import { type ModelsDialogue } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { ImportIcon } from '../../icons/ImportIcon';
import { DialogueUtils } from './utils';

export function DialogueImportModal(props: {
  onCancel: () => void;
  onSave: (jsonText: string) => void;
}) {
  const { onCancel, onSave } = props;
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSave = useLiveCallback(() => {
    try {
      const jsonText = textareaRef.current?.value || '';
      onSave(jsonText);
    } catch (error) {
      setError('Invalid JSON format. Please check your input.');
    }
  });

  return (
    <div className='w-full h-full p-5 flex flex-col text-white'>
      <div className='flex-none w-full py-2'>
        <div className='font-bold text-lg'>Import Dialogue</div>
      </div>

      <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
        <textarea
          ref={textareaRef}
          className='w-full h-full field p-2 m-0 font-mono text-sm'
          placeholder='Paste your dialogue JSON here...'
          autoFocus
        />
      </div>

      {error && <div className='text-red-500 text-sm mb-4'>{error}</div>}

      <div className='mt-auto w-full flex items-center justify-end gap-4'>
        <button
          type='button'
          className='btn-secondary w-40 py-2'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn-primary w-40 py-2'
          onClick={handleSave}
        >
          Import
        </button>
      </div>
    </div>
  );
}

export function useTriggerImportDialogueModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    async (props: {
      onImport: (jsonText: string) => void;
      onCancel?: () => void;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            onClose={() => {
              props.onCancel?.();
              p.internalOnCancel();
            }}
            innerClassName='rounded-xl'
            borderStyle='gray'
          >
            <DialogueImportModal
              onCancel={() => {
                props.onCancel?.();
                p.internalOnCancel();
              }}
              onSave={(jsonText) => {
                props.onImport(jsonText);
                p.internalOnConfirm();
              }}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}

export function DialogueImportButton(props: {
  editor: Editor;
  onChange: (dialogue: ModelsDialogue) => void;
}) {
  const { editor } = props;

  const triggerModal = useTriggerImportDialogueModal();
  const handleClick = () => {
    triggerModal({
      onImport: (jsonText) => {
        const rawDialogue = JSON.parse(jsonText);
        const dialogue = DialogueUtils.AISchemaToDialogue(rawDialogue);
        props.onChange(dialogue);
      },
      onCancel: () => {
        editor.chain().focus().run();
      },
    });
  };

  return (
    <button
      type='button'
      onClick={() => handleClick()}
      className='text-icon-gray hover:text-white'
      title='Import Dialogue'
    >
      <ImportIcon className='w-4 h-4 fill-current' />
    </button>
  );
}
