import { type Editor, type NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';
import { Plugin, PluginKey, type Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { useRef, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useMedia } from '../../../hooks/useMedia';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../../utils/media';
import { ImageIcon } from '../../icons/ImageIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';

interface HighlightPluginState {
  highlightRange: { from: number; to: number } | null;
}

const highlightPluginKey = new PluginKey('highlight');

// Highlight plugin for text highlighting when clicking on images
const highlightPlugin = new Plugin<HighlightPluginState>({
  key: highlightPluginKey,
  state: {
    init(): HighlightPluginState {
      return {
        highlightRange: null,
      };
    },
    apply(tr: Transaction, prev: HighlightPluginState): HighlightPluginState {
      const meta = tr.getMeta(highlightPluginKey);
      if (!meta) return prev;

      if (meta.type === 'set-highlight') {
        // If we have a mediaId, find its position and calculate range to next image
        if (!meta.mediaId) return prev;

        const { doc } = tr;

        // Find all image marks with their positions and mediaIds
        const imageMarks: Array<{ pos: number; mediaId: string }> = [];
        doc.descendants((node, pos) => {
          if (node.type.name === 'mark' && node.attrs.type === 'image') {
            imageMarks.push({ pos, mediaId: node.attrs.name });
          }
          return true;
        });

        // Sort by position to maintain document order
        imageMarks.sort((a, b) => a.pos - b.pos);

        // Find current image position
        const currentIndex = imageMarks.findIndex(
          (mark) => mark.mediaId === meta.mediaId
        );
        if (currentIndex === -1) return { highlightRange: null };

        const currentPos = imageMarks[currentIndex].pos;
        const endPos =
          currentIndex < imageMarks.length - 1
            ? imageMarks[currentIndex + 1].pos
            : doc.nodeSize - 2; // Default to end of document if it's the last image

        return {
          highlightRange: { from: currentPos + 1, to: endPos },
        };
      }

      // clear the highlight
      return {
        highlightRange: null,
      };
    },
  },
  props: {
    decorations(state) {
      const pluginState = this.getState(state);
      if (!pluginState || !pluginState.highlightRange)
        return DecorationSet.empty;

      const { highlightRange } = pluginState;
      const decorations = [
        Decoration.inline(highlightRange.from, highlightRange.to, {
          class: 'highlighted-text',
        }),
      ];

      return DecorationSet.create(state.doc, decorations);
    },
  },
});

export const dialogueEditorMarkImagePlugins = [highlightPlugin];

export function AddImageMarkButton(props: { editor: Editor }) {
  const { editor } = props;

  const triggerMediaSearchModal = useTriggerMediaSearchModal();
  const handleClick = () => {
    triggerMediaSearchModal({
      video: false,
      onUploadSuccess: (media) => {
        editor
          .chain()
          .focus()
          .insertContent({
            type: 'mark',
            attrs: {
              type: 'image',
              name: media.id, // use mediaId as name
            },
          })
          .focus()
          .run();
      },
      onCancel: () => {
        editor.chain().focus().run();
      },
    });
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className='text-icon-gray hover:text-white'
    >
      <ImageIcon className='w-4 h-4 fill-current' />
    </button>
  );
}

// export function AISlideshowButton(props: {
//   editor: Editor;
//   dialogue: ModelsDialogue;
//   defaultPersonalityId: string;
// }) {
//   const { editor, dialogue, defaultPersonalityId } = props;

//   const {
//     call: generateImages,
//     state: {
//       state: { isRunning: isGeneratingImages },
//     },
//   } = useLiveAsyncCall(async () => {
//     const dialogueSchema = DialogueUtils.DialogueToAISchema(dialogue);
//     const response = await apiService.promptTemplate.runTemplate({
//       promptTemplateMappingKey: 'dialogue/add-images',
//       variables: {
//         dialogue: JSON.stringify(dialogueSchema),
//       },
//     });
//     const args = response.data.toolCalls.at(0)?.args;
//     if (!args) {
//       throw new Error('No tool calls returned');
//     }
//     const dialogue = DialogueUtils.AISchemaToDialogue(args);

//     const filledDialogue = await DialogueUtils.FillDialogueImages(dialogue);
//     const filledContent = fromDialogueToEditor(
//       filledDialogue,
//       defaultPersonalityId
//     );
//     editor.chain().setContent(filledContent).focus().run();
//   });

//   return (
//     <ActionSheet
//       actions={[
//         {
//           key: 'add-images',
//           kind: 'button',
//           text: 'Add Images to Script',
//           onClick: () => generateImages(),
//           disabled:
//             isGeneratingImages ||
//             dialogue.entries.every((entry) => !entry.script.trim()),
//         },
//       ]}
//       placement='bottom'
//       optionsChildren={<AIWriterIcon className='w-4 h-4 fill-current' />}
//       containerClassName='w-4 h-4 text-icon-gray hover:text-white'
//     />
//   );
// }

export function DialogueEditorMarkImage(props: NodeViewProps) {
  const { editor } = props;
  const { name: mediaId, query } = props.node.attrs;

  const imageRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  const { media } = useMedia(mediaId);

  const url = MediaUtils.PickMediaUrl(media, {
    priority: ImagePickPriorityLowToHigh,
  });

  const handleImageClick = () => {
    editor.view.dispatch(
      editor.state.tr.setMeta(highlightPluginKey, {
        type: 'set-highlight',
        mediaId,
      })
    );

    setIsActive(true);
  };

  const handleOutsideClick = useLiveCallback(() => {
    editor.view.dispatch(
      editor.state.tr.setMeta(highlightPluginKey, {
        type: 'clear-highlight',
      })
    );

    setIsActive(false);
  });

  useOutsideClick(imageRef, handleOutsideClick, null, !isActive);

  return (
    <NodeViewWrapper
      as='div'
      type='image'
      media-id={mediaId}
      query={query}
      className={`group inline-block align-middle cursor-pointer`}
      draggable
      data-drag-handle=''
    >
      <div
        ref={imageRef}
        onClick={handleImageClick}
        className={`w-6 h-4 flex items-center justify-center rounded-sm border-2 ${
          isActive ? 'border-[#4CE3B3]' : 'border-transparent'
        }`}
      >
        {url ? (
          <img src={url} alt='' className='w-full h-full object-cover' />
        ) : (
          <ImageIcon className={`w-4 h-4 fill-current text-icon-gray`} />
        )}
      </div>
    </NodeViewWrapper>
  );
}
