import { FileInput } from '@uppy/react';
import { useLayoutEffect } from 'react';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { formatFileSize } from '../../../utils/string';
import {
  UGCFileManagerProvider,
  useUGCFileManager,
  useUGCFiles,
} from '../../Game/UGC/CustomGameFileManager';
import { type UGCFile } from '../../Game/UGC/types';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { DownloadIcon } from '../../icons/DownloadIcon';
import { ShareIcon } from '../../icons/ShareIcon';
import { FileTypeIcon, getFileTypeInfo } from './FileTypeIcon';

const formatFileInfo = (file: UGCFile) => {
  if (file.remote) {
    const arr = [];
    const { displayName } = getFileTypeInfo(file.file.name);
    arr.push(displayName);
    const size =
      file.file.originalContentLength ||
      file.file.media?.media?.formats?.[0]?.size ||
      0;
    if (size) {
      arr.push(formatFileSize(size));
    }
    const date = new Date(file.file.createdAt).toLocaleDateString();
    arr.push(`Uploaded ${date}`);
    return arr.join(' • ');
  }

  const { displayName } = getFileTypeInfo(file.file.name);
  const size = formatFileSize(file.file.size);
  return `${displayName} • ${size} • ${
    file.status === 'failed' ? 'Upload failed' : 'Uploading...'
  }`;
};

function Internal(props: { pack: DtoGamePack }) {
  const fileman = useUGCFileManager();
  const files = useUGCFiles();

  useLayoutEffect(() => {
    apiService.gamePack.getUGCFiles(props.pack.id).then((resp) => {
      fileman.init(props.pack.id, resp.data.files);
    });
  }, [props.pack.id, fileman]);

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <p className='text-sm font-bold'>Course Knowledge</p>
        <p className='text-xs text-icon-gray'>
          These materials will power AI-driven coaching and personalized course
          content.
        </p>
      </div>

      <div className='w-full border border-secondary rounded-lg overflow-hidden flex flex-col'>
        <div className='w-full bg-lp-gray-002 border-b border-secondary p-4 flex justify-between items-center'>
          <p className='text-base font-medium'>Documents</p>

          <label className='w-36 h-10 flex justify-center items-center gap-2 btn-primary cursor-pointer'>
            <ShareIcon className='w-4.5 h-4.5 fill-current' />
            Upload New
            <div className='hidden w-0 h-0'>
              <FileInput uppy={fileman.uppy} inputName='files' />
            </div>
          </label>
        </div>

        {files.length === 0 ? (
          <div className='flex flex-col items-center justify-center p-4'>
            <p className='text-sms text-icon-gray '>
              No documents uploaded yet
            </p>
          </div>
        ) : (
          files.map((file) => (
            <div
              key={file.file.id}
              className='w-full p-4 hover:bg-lp-gray-001 flex justify-between items-center gap-2'
            >
              <div className='flex items-center gap-3'>
                <FileTypeIcon filename={file.file.name} className='w-5 h-5' />
                <div>
                  <p className='font-medium truncate'>{file.file.name}</p>

                  <p className='text-xs text-icon-gray truncate'>
                    {formatFileInfo(file)}
                  </p>
                </div>
              </div>

              <div className='flex-none flex items-center gap-2'>
                {file.remote && file.file.downloadable && (
                  <button
                    type='button'
                    className='w-6 h-6 flex items-center justify-center text-icon-gray hover:text-white'
                    onClick={() => fileman.downloadFile(file.file.id)}
                  >
                    <DownloadIcon className='w-5 h-5 fill-current' />
                  </button>
                )}

                <button
                  type='button'
                  className='w-6 h-6 flex items-center justify-center text-icon-gray hover:text-white'
                  onClick={() => fileman.deleteFile(file.file.id)}
                >
                  <DeleteIcon className='w-5 h-5 fill-current' />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export function TrainingEditorKnowledge(props: { pack: DtoGamePack }) {
  return (
    <UGCFileManagerProvider>
      <Internal pack={props.pack} />
    </UGCFileManagerProvider>
  );
}
