import cloneDeep from 'lodash/cloneDeep';
import { type ReactNode, useLayoutEffect, useState } from 'react';

import { type Block, type ResultsBlock } from '@lp-lib/game';

import { toDTOBlock } from '../../../utils/api-dto';
import { blockTypePlayable } from '../../GameV2/blocks/block-grade-result';
import { PointsBadge } from '../../GameV2/blocks/Results/PointsBadge';
import { CommonButton } from '../../GameV2/design/Button';
import { ScrollableContent } from '../../GameV2/design/ScrollableContent';
import { SparkBlockIcon } from '../../icons/Block';
import { EyeIcon } from '../../icons/EyeIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { VisibleOffIcon } from '../../icons/VisibleOffIcon';
import { SwitcherControlled } from '../../Switcher';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import { type TrainingEditorControlAPI } from './TrainingEditorControlAPI';
import { type TrainingSlideEditorProps } from './types';
import { TrainingEditorUtils } from './utils';

function usePrecedingPlayableBlocks(
  ctrl: TrainingEditorControlAPI,
  blockId: string
) {
  const [precedingPlayableBlocks, setPrecedingPlayableBlocks] = useState<
    Block[]
  >([]);
  useLayoutEffect(() => {
    return ctrl.on((gameBlocks) => {
      const blocks = gameBlocks.flatMap((entry) => entry.blocks);
      const idx = blocks.findIndex((b) => b.id === blockId);
      if (idx === -1) {
        setPrecedingPlayableBlocks([]);
        return;
      }
      setPrecedingPlayableBlocks(
        blocks
          .slice(0, idx)
          .filter((b) => blockTypePlayable(toDTOBlock(b).type))
      );
    });
  }, [ctrl, blockId]);
  return precedingPlayableBlocks;
}

function HideButton(props: {
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
}) {
  return (
    <button
      type='button'
      className='w-12 h-12 flex-shrink-0 bg-dark-gray rounded-xl flex items-center justify-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon}
    </button>
  );
}

function BlockSlot(props: { children?: ReactNode; className?: string }) {
  return (
    <div
      className={`flex-1 h-12 bg-dark-gray rounded-lg flex items-center gap-2.5 px-2.5 truncate ${
        props.className ?? ''
      }`}
    >
      {props.children}
    </div>
  );
}

function BlockPreview(props: {
  block: Block;
  onSelectBlock: (blockId: string) => void;
}) {
  const { block, onSelectBlock } = props;
  const blockTitle = TrainingEditorUtils.BlockTitle(block);
  return (
    <>
      <SparkBlockIcon
        blockType={block.type}
        className='flex-shrink-0 w-6 h-6'
      />
      <span className='text-sms truncate'>{blockTitle}</span>
      <button
        className='ml-auto'
        type='button'
        onClick={() => onSelectBlock(block.id)}
      >
        <NewWindowIcon className='w-3.5 h-3.5 fill-current' />
      </button>
    </>
  );
}

export function ResultsBlockEditor(
  props: TrainingSlideEditorProps<ResultsBlock> & {
    ctrl: TrainingEditorControlAPI;
    gameId: string;
  }
) {
  const { gameId, block, ctrl } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const precedingPlayableBlocks = usePrecedingPlayableBlocks(ctrl, block.id);

  if (precedingPlayableBlocks.length === 0) {
    return (
      <div className='flex flex-col items-center my-5 gap-7.5'>
        <div className='w-100 text-tertiary text-xl text-center font-medium'>
          The Results Slide shows the learner's performance on completed
          interactive slides, but none have been added.
        </div>
        <div className='w-100 flex flex-col gap-2'>
          {[...Array(4)].map((_, i) => (
            <div key={i} className='flex items-center justify-center gap-2'>
              <HideButton disabled />
              <BlockSlot />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const excludeBlockIds = block.fields.excludeBlockIds;
  const updateExcludeBlockIds = (blockId: string, include: boolean) => {
    const set = new Set(precedingPlayableBlocks.map((b) => b.id));
    const updated = include ? [blockId] : [];
    for (const id of excludeBlockIds) {
      if (id === blockId) continue;
      if (set.has(id)) {
        updated.push(id);
      }
    }
    onChange('excludeBlockIds', updated);
    onBlur('excludeBlockIds', updated);
  };

  const onSelectBlock = (blockId: string) => {
    ctrl.selectBlock(gameId, blockId);
  };

  return (
    <div className='flex flex-col items-center h-full'>
      {block.fields.showScore && (
        <PointsBadge currPoints={100} totalPoints={100} className='mt-7.5' />
      )}
      <div className='w-100 flex-1 overflow-auto mt-7.5'>
        <ScrollableContent>
          <div className='flex flex-col gap-2'>
            {precedingPlayableBlocks.map((block) => (
              <div
                key={block.id}
                className='flex items-center justify-center gap-2'
              >
                <HideButton
                  icon={
                    excludeBlockIds.includes(block.id) ? (
                      <VisibleOffIcon className='w-5 h-5 fill-current' />
                    ) : (
                      <EyeIcon className='w-5 h-5 fill-current' />
                    )
                  }
                  onClick={() => {
                    updateExcludeBlockIds(
                      block.id,
                      !excludeBlockIds.includes(block.id)
                    );
                  }}
                />
                <BlockSlot
                  className={
                    excludeBlockIds.includes(block.id)
                      ? 'opacity-40 pointer-events-none'
                      : ''
                  }
                >
                  <BlockPreview block={block} onSelectBlock={onSelectBlock} />
                </BlockSlot>
              </div>
            ))}
          </div>
        </ScrollableContent>
      </div>
      <footer
        className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
      >
        <CommonButton
          variant='gray'
          disabled
          styles={{ size: 'h-full' }}
          style={{
            aspectRatio: '1/1',
          }}
        >
          <RefreshIcon className='w-4 h-4 fill-current' />
        </CommonButton>
        <CommonButton
          variant='correct'
          className='flex-none'
          styles={{
            disabled: 'disabled:opacity-100 disabled:cursor-auto',
          }}
          disabled
        >
          Continue
        </CommonButton>
      </footer>
    </div>
  );
}

export function ResultsBlockSidebarEditor(
  props: TrainingSlideEditorProps<ResultsBlock>
) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Mentor Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={props.block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={props.block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Show score</p>
        <div className='flex items-center justify-between'>
          <div>
            <p className='text-sms'>Include points in summary?</p>
          </div>
          <SwitcherControlled
            name={`${props.block.id}-show-score `}
            checked={props.block.fields.showScore}
            onChange={(checked: boolean): void => {
              onChange('showScore', checked);
              onBlur('showScore', checked);
            }}
          />
        </div>
      </label>
    </div>
  );
}
