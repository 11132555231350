import cloneDeep from 'lodash/cloneDeep';
import { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Select, { type SingleValue } from 'react-select';

import type {
  BlockAnimations,
  SlideBlock,
  SlideBlockAnimationKey,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import type { Option } from '../../../common/Utilities';
import {
  type BlockAnimator,
  defaultFadeInAnimation,
} from '../../../GameV2/apis/BlockAnimationControl';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { PlusIcon } from '../../../icons/PlusIcon';
import { DialogueUtils } from '../../../VoiceOver/Dialogue/utils';
import { useTrainingSlideEditor } from '../hooks';
import type { TrainingSlideEditorProps } from '../types';

// TODO(falcon): can we specify a type constraint based on the block and blockfieldS?
type AnimatableBlock = SlideBlock;

type Props<T extends AnimatableBlock> = TrainingSlideEditorProps<T> & {
  getAnimatableKeysForBlock: (block: T) => string[];
  triggers: string[];
  preview?: boolean;
  animator?: BlockAnimator;
};

export function BlockAnimationsEditor<T extends AnimatableBlock>(
  props: Props<T>
) {
  const [show, setShow] = useState(false);
  const triggers = useMemo(() => {
    if (props.triggers.length === 0) {
      return (
        <p className='text-sms text-icon-gray mb-1.5'>
          Add animation triggers to the dialogue with{' '}
          <span className='font-mono px-1 py-0.5 bg-light-gray rounded'>
            Ctrl-Space
          </span>
          .
        </p>
      );
    }
    const result = [];
    for (let i = 0; i < props.triggers.length; i++) {
      result.push(
        <TriggerEditor
          key={props.triggers[i]}
          trigger={props.triggers[i]}
          {...props}
        />
      );
    }
    return result;
  }, [props]);

  return (
    <div>
      <div className='flex items-center justify-between mb-1'>
        <p className='text-base text-white font-bold'>Animation Triggers</p>
        <button
          className='btn flex justify-center items-center text-primary text-xs hover:bg-light-gray px-2 py-1 rounded transition-colors'
          type='button'
          onClick={() => setShow((p) => !p)}
        >
          {show ? 'Hide' : 'Show'}
        </button>
      </div>
      {show && <div className='space-y-2'>{triggers}</div>}
    </div>
  );
}

function TriggerEditor<T extends AnimatableBlock>(
  props: Props<T> & { trigger: string }
) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const displayName = useMemo(() => {
    const prettyName = DialogueUtils.GetTriggerDisplayName(props.trigger);
    if (prettyName) {
      return <span title={props.trigger}>{prettyName}</span>;
    }
    return props.trigger;
  }, [props.trigger]);

  const handleDelete = useLiveCallback((key: SlideBlockAnimationKey) => {
    if (!block.fields.animations) return;
    const next = cloneDeep(block.fields.animations);
    delete next[key];
    onChange('animations', next);
    onBlur('animations', next);
  });

  const triggeredAnimations = useMemo(() => {
    const permittedKeys = props.getAnimatableKeysForBlock(block);
    const animations = block.fields.animations ?? {};
    const result = [];
    for (const [k, anim] of Object.entries(animations)) {
      if (
        anim?.enter?.triggerId !== props.trigger ||
        !permittedKeys.includes(k)
      )
        continue;
      const key = k as SlideBlockAnimationKey;
      result.push(
        <AnimationItem {...props} key={key} name={key}>
          <div className='min-w-0 flex-1 flex items-center gap-2'>
            <p className='font-bold'>{key}</p>
            <p className='text-secondary italic'>{anim.enter.name}</p>
          </div>
          <button
            type='button'
            className='flex-none btn w-5 h-5 rounded-md flex items-center justify-center text-red-002 hover:bg-secondary transition-colors'
            onClick={() => handleDelete(key)}
          >
            <DeleteIcon className='w-3 h-3 fill-current' />
          </button>
        </AnimationItem>
      );
    }
    return result;
  }, [block, handleDelete, props]);

  return (
    <div className='w-full space-y-1'>
      <div className='w-full flex items-center justify-between gap-2 text-sms px-3 py-2 bg-layer-001 rounded-md'>
        <div className='min-w-0 flex-1 flex items-center justify-between gap-2'>
          <p className='font-bold'>{displayName}</p>
          <AddBlockAnimation {...props} />
        </div>
      </div>
      <div className='w-full flex flex-col items-center pl-3 gap-1'>
        {triggeredAnimations}
      </div>
    </div>
  );
}

function AnimationItem<T extends AnimatableBlock>(
  props: Props<T> & { name: SlideBlockAnimationKey; children: React.ReactNode }
) {
  const anim = useRef<Nullable<Animation>>(null);
  const [highlightRect, setHighlightRect] = useState<Nullable<DOMRect>>(null);
  const handleMouseEnter = useLiveCallback(() => {
    if (!props.preview || !props.animator || anim.current) return;
    const preview = props.animator.getPreview(props.name);
    if (!preview) return;

    setHighlightRect(preview[0].getBoundingClientRect());
    anim.current = preview[1]();
  });
  const handleMouseLeave = useLiveCallback(() => {
    if (!anim.current) return;
    anim.current.finish();
    anim.current = null;
    setHighlightRect(null);
  });

  return (
    <div
      className='w-full flex items-center justify-between gap-2 text-sms px-3 py-2 bg-layer-001 rounded-md'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
      {highlightRect &&
        createPortal(<TargetElementRing rect={highlightRect} />, document.body)}
    </div>
  );
}

function TargetElementRing(props: { rect: DOMRect }) {
  const padding = 4;
  return (
    <div
      className='fixed bg-transparent pointer-events-none rounded-sm ring-2 ring-primary'
      style={{
        left: `${props.rect.left - padding}px`,
        top: `${props.rect.top - padding}px`,
        width: `${props.rect.width + 2 * padding}px`,
        height: `${props.rect.height + 2 * padding}px`,
      }}
    />
  );
}

function AddBlockAnimation<T extends AnimatableBlock>(
  props: Props<T> & { trigger: string }
) {
  const { block, trigger } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    // remove any animatable keys that have valid triggers
    const existingKeys = Object.entries(block.fields.animations ?? {})
      .filter(([, anim]) => {
        const triggerId = anim?.enter?.triggerId;
        return triggerId && props.triggers.includes(triggerId);
      })
      .map(([key]) => key);

    return props
      .getAnimatableKeysForBlock(block)
      .filter((k) => !existingKeys.includes(k))
      .map((o) => ({
        value: o,
        label: o,
      }));
  }, [block, props]);

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<string>>({
        override: {
          control: {
            display: 'none',
          },
        },
      }),
    []
  );

  const handleChange = useLiveCallback((value: SingleValue<Option<string>>) => {
    if (!value) return;
    const next: BlockAnimations<string> =
      cloneDeep(block.fields.animations) ?? {};
    next[value.value] = {
      enter: defaultFadeInAnimation(trigger),
    };

    onChange('animations', next);
    onBlur('animations', next);
    setIsOpen(false);
  });

  const container = useRef<HTMLDivElement | null>(null);
  useOutsideClick(container, () => setIsOpen(false));

  return (
    <div ref={container}>
      <button
        type='button'
        className='btn flex justify-center items-center text-primary gap-1 text-2xs hover:bg-light-gray px-2 py-1 rounded transition-colors'
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon className='w-2.5 h-2.5 fill-current' />
        Add Animation
      </button>
      {isOpen && (
        <Select<Option<string>, false>
          autoFocus
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          menuIsOpen
          tabSelectsValue={false}
          styles={styles}
          options={options}
          value={null}
          placeholder={'Add animation...'}
          classNamePrefix='select-box-v2'
          className='w-full text-white text-2xs'
          isSearchable={false}
          menuPlacement='auto'
          onChange={handleChange}
        />
      )}
    </div>
  );
}
