import { type MetaFunction } from '@remix-run/react';

import { Component } from '../../src/routes/trainings.$id.edit.client';
export default Component ?? (() => <></>);
export { clientLoader } from '../../src/routes/trainings.$id.edit.client';
export const HydrateFallback = () => <></>;

export const meta: MetaFunction = () => {
  return [{ title: 'Course Editor | Luna Park' }];
};
