import { useCallback } from 'react';

import { type Media } from '@lp-lib/media';

import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { MediaSearchModal } from './MediaSearch';

export function useTriggerMediaSearchModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: {
      onUploadSuccess: (media: Media) => void;
      video?: boolean;
      onCancel?: () => void;
    }) => {
      return triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            onClose={() => {
              props.onCancel?.();
              p.internalOnCancel();
            }}
            containerClassName='w-[900px] h-[600px]'
            borderStyle='gray'
          >
            <MediaSearchModal
              video={props.video}
              onUploadSuccess={(media) => {
                props.onUploadSuccess(media);
                p.internalOnConfirm();
              }}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}
