import Select from 'react-select';

import { EnumsSlideListStyle } from '@lp-lib/game/src/block';

import { useInstance } from '../../../hooks/useInstance';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';

const LIST_STYLE_CONFIG = [
  {
    label: 'Numbers',
    value: EnumsSlideListStyle.SlideListStyleNumbers,
  },
  {
    label: 'Bullets',
    value: EnumsSlideListStyle.SlideListStyleBullets,
  },
  {
    label: 'None',
    value: EnumsSlideListStyle.SlideListStyleNone,
  },
];

export function SlideBlockListStyleSelect(props: {
  value: EnumsSlideListStyle | null;
  onChange: (value: EnumsSlideListStyle | null) => void;
}) {
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsSlideListStyle>>({
      override: { control: { height: 48 } },
    })
  );

  // Default to Numbers for backward compatibility
  const currentValue = props.value ?? EnumsSlideListStyle.SlideListStyleNumbers;

  return (
    <Select<Option<EnumsSlideListStyle>>
      options={LIST_STYLE_CONFIG}
      value={LIST_STYLE_CONFIG.find((option) => option.value === currentValue)}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        props.onChange(option?.value ?? null);
      }}
      menuPlacement='auto'
      menuPosition='fixed'
    />
  );
}
