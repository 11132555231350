import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { type ConnectDragSource } from 'react-dnd';

import { type MemoryMatchBlock, type MemoryMatchCardPair } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { DragDropList } from '../../common/DragDrop';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { CommonButton, GamePlayButton } from '../../GameV2/design/Button';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { SparkBlockBackground } from '../../GameV2/design/SparkBackground';
import { DoubleRightArrow } from '../../icons/Arrows';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import {
  SparkBackgroundMediaField,
  SparkBackgroundSelect,
} from './Shared/SparkBackgroundSelect';
import { type TrainingSlideEditorProps } from './types';

export function MatchBlockEditor(
  props: TrainingSlideEditorProps<MemoryMatchBlock>
) {
  return <Left {...props} />;
}

export function MatchBlockSidebarEditor(
  props: TrainingSlideEditorProps<MemoryMatchBlock>
) {
  return <Right {...props} />;
}

export function MatchBlockEditorUnder(
  props: TrainingSlideEditorProps<MemoryMatchBlock>
) {
  return (
    <div className='flex flex-col gap-5 text-white'>
      <SparkBackgroundMediaField {...props} />
    </div>
  );
}

function Left(props: TrainingSlideEditorProps<MemoryMatchBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const { block } = props;

  const handleAddPair = useLiveCallback(() => {
    const pairs = block.fields.cardPairs ?? [];
    if (pairs.length >= 4) return;

    const next = [...pairs, { firstText: '', secondText: '' }];
    onChange('cardPairs', next);
    onBlur('cardPairs', next);
  });

  const handleRemoveCardPair = useLiveCallback((idx: number) => {
    const next = [...(block.fields.cardPairs ?? [])];
    next.splice(idx, 1);
    onChange('cardPairs', next);
    onBlur('cardPairs', next);
  });

  const handleCardPairChange = useLiveCallback(
    (idx: number, value: MemoryMatchCardPair) => {
      const next = [...(block.fields.cardPairs ?? [])];
      next[idx] = value;
      onChange('cardPairs', next);
    }
  );

  const handleCardPairBlur = useLiveCallback(
    (idx: number, value: MemoryMatchCardPair) => {
      const next = [...(block.fields.cardPairs ?? [])];
      next[idx] = value;
      onBlur('cardPairs', next);
    }
  );

  const handleMoveCardPair = useLiveCallback((from: number, to: number) => {
    const next = [...(block.fields.cardPairs ?? [])];
    const item = next[from];
    next.splice(from, 1);
    next.splice(to, 0, item);
    onChange('cardPairs', next);
    onBlur('cardPairs', next);
  });

  const items = useMemo(
    () =>
      block.fields.cardPairs.map((o, idx) => ({
        ...o,
        id: `${idx}-${o.firstText}-${o.secondText}`,
      })),
    [block.fields.cardPairs]
  );

  return (
    <>
      <SparkBlockBackground block={props.block} position='absolute' noVideo />
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main className='w-full flex-1 min-h-0 px-5 flex flex-col justify-center gap-2 lp-sm:gap-10'>
          <div className='text-white text-base text-center px-5'>
            <FFriendlyEditableText
              value={block.fields.text || ''}
              onBlur={(val) => {
                onChange('text', val);
                onBlur('text', val);
              }}
              className={`
              w-full outline-none cursor-text
              contenteditable-placeholder whitespace-pre-wrap break-words
              text-base sm:text-xl md:text-2xl lg:text-3xl
            `}
              placeholder='Your Question Here'
            />
          </div>

          <div className='w-full space-y-2'>
            <DragDropList
              type={`mm-${block.id}-pairs`}
              items={items}
              onMove={handleMoveCardPair}
              render={({ item, index, drag, ref, style }) => {
                return (
                  <div className='' ref={ref} style={style}>
                    <CardPairEditor
                      onClick={() => handleRemoveCardPair(index)}
                      pair={item}
                      onChange={(val) => {
                        handleCardPairChange(index, val);
                      }}
                      onBlur={(val) => {
                        handleCardPairBlur(index, val);
                      }}
                      index={index}
                      drag={drag}
                    />
                  </div>
                );
              }}
            />

            {block.fields.cardPairs.length < 4 && (
              <div className='px-5'>
                <button
                  type='button'
                  className='w-full h-10 btn bg-transparent border border-dashed border-secondary italic text-secondary text-sm'
                  onClick={handleAddPair}
                >
                  Add Pair (max 4)
                </button>
              </div>
            )}
          </div>
        </main>
        <footer className='hidden lp-sm:invisible w-full px-3 pt-3 pb-5 lp-sm:flex justify-center'>
          <CommonButton variant='correct' />
        </footer>
      </div>
    </>
  );
}

function CardPairEditor(props: {
  onClick: () => void;
  pair: MemoryMatchCardPair;
  onChange: (val: MemoryMatchCardPair) => void;
  onBlur: (val: MemoryMatchCardPair) => void;
  index: number;
  drag: ConnectDragSource;
}) {
  return (
    <div className='relative w-full group'>
      <div className='absolute group-hover:flex hidden inset-0'>
        <div
          className='absolute left-0 top-0 bottom-0 flex items-center text-white cursor-move'
          ref={props.drag}
        >
          <MenuIcon className='w-3.5 h-3.5 fill-current' />
        </div>
        <div
          className='absolute right-0 top-0 bottom-0 flex items-center text-red-001 cursor-pointer'
          onClick={props.onClick}
        >
          <DeleteIcon className='w-3.5 h-3.5 fill-current' />
        </div>
      </div>
      <div className='relative mx-5 flex items-center text-white'>
        <EditableMatchButton {...props} field='firstText' />
        <div className='relative w-2 h-4 flex-none'>
          <div className='absolute inset-0 z-3'>
            <DoubleRightArrow className='w-auto h-full fill-current' />
          </div>
        </div>
        <EditableMatchButton {...props} field='secondText' />
      </div>
    </div>
  );
}

function EditableMatchButton(props: {
  pair: MemoryMatchCardPair;
  field: 'firstText' | 'secondText';
  onChange: (val: MemoryMatchCardPair) => void;
  onBlur: (val: MemoryMatchCardPair) => void;
  index: number;
}) {
  const handleBlur = useLiveCallback((val: string) => {
    const next = {
      ...props.pair,
      [props.field]: val,
    };
    props.onChange(next);
    props.onBlur(next);
  });

  return (
    <GamePlayButton
      variant='gray'
      disabled
      styles={{
        text: 'text-xs font-bold text-white break-words',
        size: 'flex-1 min-w-0 h-8 xl:h-12 2xl:h-18',
        spacing: 'px-2 py-1',
        disabled: 'disabled:opacity-100 disabled:cursor-auto',
      }}
    >
      <FFriendlyEditableText
        value={props.pair[props.field] || ''}
        onBlur={handleBlur}
        className='w-full outline-none cursor-text contenteditable-placeholder whitespace-pre-wrap break-words'
        placeholder={`Card ${props.index + 1}${
          props.field === 'firstText' ? 'A' : 'B'
        }`}
        style={{
          '--placeholder-color': 'white',
        }}
      />
    </GamePlayButton>
  );
}

function Right(props: TrainingSlideEditorProps<MemoryMatchBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Points Earned Per Match
        </p>
        <PointsInput
          defaultValue={block.fields.pointsPerMatch}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('pointsPerMatch', value);
            onBlur('pointsPerMatch', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Background</p>
        <SparkBackgroundSelect
          value={block.fields.sparkBackgroundOption}
          onChange={(value) => {
            onChange('sparkBackgroundOption', value);
            onBlur('sparkBackgroundOption', value);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
    </div>
  );
}
