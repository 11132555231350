import { DocumentIcon } from '../../icons/DocumentIcon';
import {
  AudioIcon,
  CodeIcon,
  ImageIcon,
  PDFIcon,
  PowerPointIcon,
  TextIcon,
  VideoIcon,
  WordIcon,
} from '../../icons/FileIcons';

type FileType = {
  icon: React.ReactNode;
  displayName: string;
};

const FILE_TYPE_GROUPS = {
  images: {
    extensions: ['jpg', 'jpeg', 'png', 'gif'],
    icon: <ImageIcon className='text-[#279ea2]' />,
    displayName: 'Image',
  },
  videos: {
    extensions: ['mp4', 'webm', 'mov'],
    icon: <VideoIcon className='text-[#b32828]' />,
    displayName: 'Video',
  },
  audios: {
    extensions: ['mp3', 'wav', 'm4a'],
    icon: <AudioIcon className='text-[#278347]' />,
    displayName: 'Audio',
  },
  documents: {
    word: {
      extensions: ['doc', 'docx'],
      icon: <WordIcon className='text-[#2B579A]' />,
      displayName: 'Word Document',
    },
    pdf: {
      extensions: ['pdf'],
      icon: <PDFIcon className='text-red-500' />,
      displayName: 'PDF',
    },
    powerpoint: {
      extensions: ['pptx'],
      icon: <PowerPointIcon className='text-[#D24726]' />,
      displayName: 'PowerPoint',
    },
    text: {
      extensions: ['txt'],
      icon: <TextIcon className='text-secondary' />,
      displayName: 'Text File',
    },
    markdown: {
      extensions: ['md'],
      icon: <TextIcon className='text-secondary' />,
      displayName: 'Markdown',
    },
    code: {
      extensions: [
        'c',
        'cpp',
        'cs',
        'css',
        'go',
        'html',
        'java',
        'js',
        'json',
        'php',
        'py',
        'rb',
        'sh',
        'tex',
        'ts',
      ],
      icon: <CodeIcon className='text-secondary' />,
      displayName: 'Code',
    },
  },
};

const FILE_TYPES: Record<string, FileType> = {
  ...FILE_TYPE_GROUPS.images.extensions.reduce(
    (acc, ext) => ({
      ...acc,
      [ext]: {
        icon: FILE_TYPE_GROUPS.images.icon,
        displayName: FILE_TYPE_GROUPS.images.displayName,
      },
    }),
    {}
  ),

  ...FILE_TYPE_GROUPS.videos.extensions.reduce(
    (acc, ext) => ({
      ...acc,
      [ext]: {
        icon: FILE_TYPE_GROUPS.videos.icon,
        displayName: FILE_TYPE_GROUPS.videos.displayName,
      },
    }),
    {}
  ),

  ...FILE_TYPE_GROUPS.audios.extensions.reduce(
    (acc, ext) => ({
      ...acc,
      [ext]: {
        icon: FILE_TYPE_GROUPS.audios.icon,
        displayName: FILE_TYPE_GROUPS.audios.displayName,
      },
    }),
    {}
  ),

  ...Object.values(FILE_TYPE_GROUPS.documents).reduce(
    (acc, group) => ({
      ...acc,
      ...group.extensions.reduce(
        (groupAcc, ext) => ({
          ...groupAcc,
          [ext]: {
            icon: group.icon,
            displayName: group.displayName,
          },
        }),
        {}
      ),
    }),
    {}
  ),
};

export function getFileTypeInfo(filename: string): FileType {
  const ext = filename.split('.').pop()?.toLowerCase() || '';
  return (
    FILE_TYPES[ext] || {
      icon: <DocumentIcon className='text-gray-400' />,
      displayName: 'Document',
    }
  );
}

interface FileTypeIconProps {
  filename: string;
  className?: string;
}

export function FileTypeIcon({ filename, className = '' }: FileTypeIconProps) {
  const { icon } = getFileTypeInfo(filename);
  return <div className={`flex-none ${className}`}>{icon}</div>;
}
