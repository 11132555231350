import { type DtoPersonality } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../../services/api-service';
import { DialogueUtils } from '../../../../VoiceOver/Dialogue/utils';

export class SlideEditorUtils {
  static async GenerateCode(
    desc: string,
    personalities: DtoPersonality[],
    defaultPersonalityId: string
  ) {
    const speakers = personalities.map((p) => ({
      id: p.id,
      name: p.name,
      description: p.description,
    }));

    const resp = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'slide/generate-html',
      variables: {
        desc,
        speakers: JSON.stringify(speakers),
      },
      maxTokens: 15360,
    });

    let dialogue, html;
    const args = resp.data.toolCalls?.at(0)?.args;
    if (args) {
      dialogue = args.dialogue;
      html = args.html;
    } else {
      // Extract dialogue (JSON) and html from content using regex
      const content = resp.data.content || '';
      const dialogueMatch = content.match(/```json\n([\s\S]*?)\n```/);
      const htmlMatch = content.match(/```html\n([\s\S]*?)\n```/);

      if (!dialogueMatch || !htmlMatch) {
        throw new Error('Could not extract dialogue and html from content');
      }
      try {
        dialogue = JSON.parse(dialogueMatch[1]);
      } catch (e) {
        throw new Error('Failed to parse dialogue JSON');
      }
      html = htmlMatch[1];
    }

    if (!dialogue || !html) {
      throw new Error('Failed to get dialogue or html content');
    }

    const result = {
      dialogue: DialogueUtils.AISchemaToDialogue(dialogue),
      html,
    };

    const personalityMap = new Map(personalities.map((p) => [p.id, p]));
    result.dialogue.entries.forEach((entry) => {
      entry.personalityId =
        personalityMap.get(entry.personalityId)?.avatarId ||
        defaultPersonalityId;
    });

    return result;
  }
}
