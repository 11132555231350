import { useEffect, useMemo, useState } from 'react';

import type {
  DtoBlock,
  DtoGame,
  DtoGamePack,
  DtoProgression,
} from '@lp-lib/api-service-client/public';

import { type useLearningAnalytics } from '../../../analytics/learning';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import type { Game } from '../../../types/game';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { Overworld } from '../../GameV2/Overworld';
import { PlayCursor } from '../../GameV2/PlayCursor';
import { Playground } from '../../GameV2/Playground';
import { DesktopIcon } from '../../icons/DesktopIcon';
import { MobileIcon } from '../../icons/MobileIcon';
import { XIcon } from '../../icons/XIcon';
import { useUserGetter } from '../../UserContext';
import { DevicePreview } from './Shared/DevicePreview';
import type { TrainingDevice } from './types';
import { TrainingEditorUtils } from './utils';

export function TrainingPreview(props: {
  defaultDevice: TrainingDevice;
  onClose: () => void;
  pack: DtoGamePack;
  stores: GameEditorStore[];
  selectedStore: Nullable<GameEditorStore>;
  analytics: ReturnType<typeof useLearningAnalytics>;
}) {
  const [device, setDevice] = useState<TrainingDevice>(props.defaultDevice);
  const getUser = useUserGetter();
  const pack = useMemo(() => fromDTOGamePack(props.pack), [props.pack]);
  const games = useMemo(() => {
    return props.stores
      .map((store) => store.state.game)
      .filter(Boolean) as Game[];
  }, [props.stores]);
  const [overworldProgression, setOverworldProgression] =
    useState<DtoProgression>(() => {
      const selectedGameIndex = games.findIndex(
        (game) => game.id === props.selectedStore?.state.game?.id
      );
      return TrainingEditorUtils.PreviewOverworldProgression(
        games,
        selectedGameIndex
      );
    });
  const [cursor, setCursor] = useState<PlayCursor>(() => {
    const blocks: DtoBlock[] = [];
    const minigames: DtoGame[] = [];
    const minigameBlocks = new Map<DtoGame['id'], DtoBlock[]>();
    for (const store of props.stores) {
      const game = store.state.game;
      if (!game) continue;
      minigames.push(game as unknown as DtoGame);
      const storeBlocks = store.blockEditorStore.state
        .blocks as unknown as DtoBlock[];
      minigameBlocks.set(game.id, storeBlocks);
      blocks.push(...storeBlocks);
    }

    const currentMinigame = props.selectedStore?.state.game;
    const currentMinigameBlocks =
      props.selectedStore?.blockEditorStore.state.blocks;
    let currentMinigameIndex = 0;
    let currentBlockIndex = 0;
    if (currentMinigame) {
      currentMinigameIndex = Math.max(
        minigames.findIndex((game) => game.id === currentMinigame.id),
        0
      );
    }
    if (currentMinigameBlocks) {
      currentBlockIndex = Math.max(
        currentMinigameBlocks.findIndex(
          (block) => block.id === props.selectedStore?.state.selectedBlockId
        ),
        0
      );
    }

    return new PlayCursor(
      props.pack,
      minigames,
      minigameBlocks,
      blocks,
      null,
      currentMinigameIndex,
      currentBlockIndex
    );
  });
  const [view, setView] = useState<'overworld' | 'playground'>(() => {
    if (props.selectedStore?.state.selectedBlockId) {
      return 'playground';
    } else {
      return 'overworld';
    }
  });
  const handlePlaygroundClose = useLiveCallback((cursor: PlayCursor) => {
    setOverworldProgression(
      TrainingEditorUtils.PreviewOverworldProgression(
        games,
        cursor.currentMinigameIndex
      )
    );
    setView('overworld');
  });
  const handleClickOverworldGame = useLiveCallback((game: Game) => {
    setCursor(cursor.withMinigame(game.id));
    setView('playground');
  });

  useEffect(() => {
    props.analytics.trackEditorCoursePreviewed({
      packId: props.pack.id,
      mode: device,
    });
  }, [device, props.analytics, props.pack.id]);

  return (
    <div className='w-full h-full bg-layer-001 px-2.5 py-1.5 flex flex-col items-center gap-2'>
      <header className='w-max h-15 px-7.5 flex-none bg-main-layer rounded-xl flex items-center justify-center gap-5'>
        <button
          type='button'
          className='text-icon-gray hover:text-white'
          onClick={props.onClose}
        >
          <XIcon className='w-6 h-6 fill-current text-icon-gray hover:text-white' />
        </button>
        {device === 'mobile' && (
          <button
            type='button'
            className='text-icon-gray hover:text-white'
            onClick={() => setDevice('desktop')}
          >
            <DesktopIcon className='w-6 h-6 fill-current' />
          </button>
        )}
        {device === 'desktop' && (
          <button
            type='button'
            className='text-icon-gray hover:text-white'
            onClick={() => setDevice('mobile')}
          >
            <MobileIcon className='w-6 h-6 fill-current' />
          </button>
        )}
      </header>

      <main
        className={`relative bg-black border border-secondary rounded-lg overflow-hidden ${
          device === 'mobile' ? 'w-98 h-174' : 'flex-1 w-full min-h-0'
        }`}
      >
        <DevicePreview>
          {view === 'overworld' ? (
            <Overworld
              pack={pack}
              games={games}
              progression={overworldProgression}
              isPreview
              onClickGame={handleClickOverworldGame}
            />
          ) : (
            <Playground
              cursor={cursor}
              preview
              onClose={() => handlePlaygroundClose(cursor)}
              onMinigameContinue={() => {
                handlePlaygroundClose(cursor.withNextMinigame());
              }}
              getUser={getUser}
            />
          )}
        </DevicePreview>
      </main>
    </div>
  );
}
