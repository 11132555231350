import 'react-popper-tooltip/dist/styles.css';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { usePopperTooltip } from 'react-popper-tooltip';
import { $path } from 'remix-routes';
import { useSnapshot } from 'valtio';

import {
  type DtoGamePack,
  EnumsGamePackChangeLevel,
  type ModelsLogicSettings,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  BlockType,
  type BlockTypeToFields,
  fromBlockType,
} from '@lp-lib/game';
import { buildBlockRefMap, removeLogicRules } from '@lp-lib/game/src/logic';

import { type useLearningAnalytics } from '../../../analytics/learning';
import { GameEditorStore } from '../../../components/Game/GameEditorStore';
import { blockTypePlayable } from '../../../components/GameV2/blocks/block-grade-result';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type Game } from '../../../types/game';
import { assertDefinedFatal } from '../../../utils/common';
import { type Action, ActionSheet } from '../../ActionSheet';
import { DragDropList } from '../../common/DragDrop';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { ArrowDownIcon } from '../../icons/Arrows/Default';
import { AssessmentIcon } from '../../icons/AssessmentIcon';
import { SparkBlockIcon } from '../../icons/Block';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { DuplicateIcon } from '../../icons/DuplicateIcon';
import { EditIcon } from '../../icons/EditIcon';
import { EyeIcon } from '../../icons/EyeIcon';
import { LockIcon } from '../../icons/LockIcon';
import { OptionsIcon } from '../../icons/OptionsIcon';
import { RenderIcon } from '../../icons/RenderIcon';
import { ToolsIcon } from '../../icons/ToolsIcon';
import { WarningIcon } from '../../icons/WarningIcon';
import { NewSlideButton } from './NewSlideButton';
import { RenameModal } from './Shared/RenameModal';
import { TrainingEditorUtils } from './utils';

function TrainingSlideActionButton(props: {
  store: GameEditorStore;
  block: Block;
  selected: boolean;
  onRename: () => void;
  onDelete: () => void;
}) {
  const { store, block, selected, onRename } = props;

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'rename',
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename,
    },
    {
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: async () => {
        store.duplicateBlock(block.id);
      },
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: props.onDelete,
    },
  ];
  if (block.type === BlockType.ROLEPLAY) {
    actions.push({
      kind: 'button',
      key: 'histories',
      icon: <EyeIcon />,
      text: 'View History',
      onClick: () => {
        window.open(
          $path('/roleplay/histories', { 'block-id': block.id }),
          '_blank'
        );
      },
    });
  }

  return (
    <ActionSheet
      containerClassName={`w-6 h-6 flex justify-center items-center ${
        selected ? 'text-white' : 'text-transparent group-hover:text-icon-gray'
      }`}
      actions={actions}
      placement='right-start'
      offset={[0, 12]}
      optionsChildren={
        <div className='w-full h-full flex justify-center items-center'>
          <OptionsIcon className='w-4 h-4 fill-current' />
        </div>
      }
    />
  );
}

type DraggingSlideItem = {
  itemId: string;
  fromIndex: number;
  toIndex: number;
  fromListId: string;
  toListId: string;
};

type SlideDropResult = {
  ok: boolean;
};

const dropIndicatorBoxShadow = '0 2px 0 0 #FBB707'; // tertiary/tangerine/yellow

function TrainingSlideItem(props: {
  store: GameEditorStore;
  block: Block;
  index: number;
  selected: boolean;
  onSelect: () => void;
  onDelete: () => void;
  getStoreById: (id: string) => GameEditorStore | undefined;
}) {
  const { store, block, index, selected, onSelect, onDelete } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const requiresRender = useMemo(
    () => TrainingEditorUtils.IsBlockRenderRequired(block),
    [block]
  );
  const isRendering = useMemo(
    () => TrainingEditorUtils.IsBlockRendering(block),
    [block]
  );
  const blockTitle = TrainingEditorUtils.BlockTitle(block);

  const handleTitleChange = useLiveCallback(async (value: string) => {
    store.blockEditorStore.updateEditingBlockFieldLocalFirst(
      block.id,
      'title',
      value
    );
  });

  const handleRename = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <RenameModal
          defaultValue={blockTitle}
          title='Rename Slide'
          onCancel={p.internalOnCancel}
          onSave={(next) => {
            handleTitleChange(next);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });

  // NOTE: useDrag/useDrop work in these components because there is an implicit
  // DndProvider in the tree due to the sortable children of
  // TrainingEditorSidebar.

  const [, drag, dragPreview] = useDrag({
    type: 'slides',
    item: (): DraggingSlideItem => {
      assertDefinedFatal(store.state.game?.id, 'game.id');
      return {
        itemId: block.id,
        fromIndex: index,
        toIndex: index,
        fromListId: store.state.game.id,
        toListId: store.state.game.id,
      };
    },
    end: async (item, monitor) => {
      if (!monitor.didDrop()) return;
      const dropResult: SlideDropResult | null = monitor.getDropResult();
      if (!dropResult || !dropResult.ok) return;

      if (item.fromListId !== item.toListId) {
        // different game!
        const from = props.getStoreById(String(item.fromListId));
        const to = props.getStoreById(String(item.toListId));

        if (!from || !to || !from.state.game?.id || !to.state.game?.id) return;

        // NOTE: we are breaking convention by doing the API calls outside
        // of the GameEditorStore. This is because each GameEditorStore is
        // 1:1 with the game. If we perform these operations on each
        // individual store, sequentially, it results in the UI jumping
        // because we first remove a block from one store (network call,
        // update the store, trigger DOM update), then add it to the other
        // store (network call, update the store, trigger DOM update). To
        // avoid this, we perform both network operations, then update the
        // stores synchronously. React will then perform the DOM flush in
        // the same tick.

        const blocksFrom = (
          await apiService.block.detachGameBlock(
            from.state.game.id,
            String(item.itemId)
          )
        ).data.blocks;
        const blocksTo = (
          await apiService.block.attachGameBlock({
            gameId: to.state.game.id,
            blockId: String(item.itemId),
            targetPosition: item.toIndex,
          })
        ).data.blocks;

        from.setEditingGame(from.state.game, { blocks: blocksFrom });
        to.setEditingGame(to.state.game, { blocks: blocksTo });
      } else {
        // same game, reorder

        // Moving requires removing the element at the index, which changes the
        // subsequent indices! Account for this.
        const to =
          item.toIndex > item.fromIndex ? item.toIndex - 1 : item.toIndex;
        await store.moveBlocks(item.fromIndex, to);
      }
    },
  });

  const [{ isHovered }, drop] = useDrop({
    accept: 'slides',
    collect: (monitor) => ({
      isHovered: monitor.isOver({ shallow: true }),
    }),
    hover(item: DraggingSlideItem, _monitor) {
      assertDefinedFatal(store.state.game?.id, 'game.id');
      item.toIndex = index + 1;
      item.toListId = store.state.game?.id;
    },
    drop() {
      const result: SlideDropResult = { ok: true };
      return result;
    },
  });

  const dropTargetRef = useRef<HTMLDivElement | null>(null);
  const dragHandleAndPreviewRef = useRef<HTMLDivElement | null>(null);

  drop(dropTargetRef);
  dragPreview(drag(dragHandleAndPreviewRef));

  return (
    <div
      className={`w-full flex items-center cursor-pointer group
        ${selected ? 'text-white' : 'text-icon-gray'}
      `}
      style={{
        boxShadow: isHovered ? dropIndicatorBoxShadow : '',
      }}
      onClick={onSelect}
      ref={(el) => {
        dropTargetRef.current = el;
        dragHandleAndPreviewRef.current = el;
      }}
    >
      <div
        className={`flex-none w-1 h-6 rounded-lg ${
          selected ? 'bg-lp-red-002' : 'bg-transparent'
        }`}
      />
      <div className='ml-1 flex-1 overflow-hidden flex items-center hover:bg-light-gray p-0.5 pr-1.5 rounded-lg'>
        <div className='flex-none w-4.5 h-full text-sms flex justify-center items-center'>
          {index + 1}
        </div>
        <div className='flex-none ml-1 w-6 h-6 relative'>
          <SparkBlockIcon
            blockType={block.type}
            fields={block.fields}
            className={`
              w-full h-full
              ${requiresRender || isRendering ? 'opacity-30' : 'opacity-100'}
            `}
          />
          {(requiresRender || isRendering) && (
            <div className='absolute inset-0 bg-blue-005 bg-opacity-30 rounded-md flex justify-center items-center text-white'>
              {isRendering ? (
                <LockIcon className='w-4 h-4 fill-current' />
              ) : (
                <RenderIcon className='w-4 h-4 fill-current' />
              )}
            </div>
          )}
        </div>
        <div className='flex-1 ml-2 text-sms truncate'>{blockTitle}</div>
        <TrainingSlideActionButton
          store={store}
          block={block}
          selected={selected}
          onRename={handleRename}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
}

function AssessmentActionsButton(props: {
  selected: boolean;
  onDelete: () => void;
  onRename: () => void;
}) {
  const { selected, onDelete, onRename } = props;

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'rename',
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: onDelete,
    },
  ];

  return (
    <ActionSheet
      containerClassName={`w-6 h-6 flex justify-center items-center ${
        selected ? 'text-white' : 'text-transparent group-hover:text-icon-gray'
      }`}
      actions={actions}
      placement='right-start'
      offset={[0, 12]}
      optionsChildren={
        <div className='w-full h-full flex justify-center items-center'>
          <OptionsIcon className='w-4 h-4 fill-current' />
        </div>
      }
    />
  );
}

function TrainingSlideGroupActionsButton(props: {
  selected: boolean;
  onDelete: () => void;
  onRename: () => void;
}) {
  const { selected, onDelete, onRename } = props;

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'rename',
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: onDelete,
    },
  ];

  return (
    <ActionSheet
      containerClassName={`w-6 h-6 flex justify-center items-center ${
        selected ? 'text-white' : 'text-transparent group-hover:text-icon-gray'
      }`}
      actions={actions}
      placement='right-start'
      offset={[0, 12]}
      optionsChildren={
        <div className='w-full h-full flex justify-center items-center'>
          <OptionsIcon className='w-4 h-4 fill-current' />
        </div>
      }
    />
  );
}

function TrainingSlideGroupHeader(props: {
  store: GameEditorStore;
  game: Game;
  selected: boolean;
  onSelect: () => void;
  expanded: boolean;
  onExpand: () => void;
  onDelete: (id: string) => void;
  sectionNumber: number;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    store,
    game,
    selected,
    onSelect,
    expanded,
    onExpand,
    onDelete,
    sectionNumber,
  } = props;

  const handleChangeName = useLiveCallback(async (value: string) => {
    const resp = await apiService.game.update(game.id, {
      name: value,
    });
    if (!resp.data.game) return;
    store.updateGame(resp.data.game);
  });

  const handleRename = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <RenameModal
          defaultValue={game.name}
          title='Rename Slide Group'
          onCancel={p.internalOnCancel}
          onSave={(next) => {
            handleChangeName(next);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });

  const [{ isHover }, drop] = useDrop({
    accept: 'slides',
    hover: (item: DraggingSlideItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;
      item.toIndex = 0;
      item.toListId = game.id;
    },

    collect: (monitor) => ({
      isHover: monitor.isOver({ shallow: true }),
    }),

    drop: (_item: DraggingSlideItem) => {
      const result: SlideDropResult = { ok: true };
      return result;
    },
  });

  return (
    <div
      className={`w-full flex items-center group cursor-pointer
        ${selected ? 'text-white' : 'text-icon-gray'}
      `}
      style={{
        boxShadow: isHover ? dropIndicatorBoxShadow : '',
      }}
      onClick={onSelect}
      ref={drop}
    >
      <div
        className={`flex-none w-1 h-6 rounded-lg ${
          selected ? 'bg-lp-red-002' : 'bg-transparent'
        }`}
      />
      <div className='ml-0.5 flex-1 overflow-hidden h-7 flex items-center hover:bg-light-gray p-0.5 pr-1.5 rounded-lg'>
        <div className='flex-none ml-1 w-11.5 h-full rounded-md bg-tertiary p-1 flex justify-between items-center gap-2 text-white'>
          <ToolsIcon className='w-4 h-4 fill-current' />
          <div className='flex-1 text-sms'>{sectionNumber}</div>
        </div>
        <div
          className={`flex-1 truncate ml-2 text-sms font-medium ${
            selected ? 'text-white' : 'text-icon-gray group-hover:text-white'
          }`}
        >
          {game.name}
        </div>
        <div
          className='ml-1 flex items-center gap-1'
          onClick={(e) => e.stopPropagation()}
        >
          <TrainingSlideGroupActionsButton
            selected={selected}
            onDelete={() => onDelete(game.id)}
            onRename={handleRename}
          />
          <button
            type='button'
            className='w-6 h-6 flex justify-center items-center'
            onClick={onExpand}
          >
            <ArrowDownIcon
              className={`w-4 h-4 fill-current ${
                expanded ? 'rotate-180' : ''
              } transition-transform`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function AssessmentHeader(props: {
  store: GameEditorStore;
  game: Game;
  selected: boolean;
  onSelect: () => void;
  expanded: boolean;
  onExpand: () => void;
  onDelete: (id: string) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const { store, game, selected, onSelect, expanded, onExpand, onDelete } =
    props;

  const handleChangeName = useLiveCallback(async (value: string) => {
    const resp = await apiService.game.update(game.id, {
      name: value,
    });
    if (!resp.data.game) return;
    store.updateGame(resp.data.game);
  });

  const handleRename = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <RenameModal
          defaultValue={game.name}
          title='Rename Assessment'
          onCancel={p.internalOnCancel}
          onSave={(next) => {
            handleChangeName(next);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });

  const [{ isHover }, drop] = useDrop({
    accept: 'slides',
    hover: (item: DraggingSlideItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;
      item.toIndex = 0;
      item.toListId = game.id;
    },

    collect: (monitor) => ({
      isHover: monitor.isOver({ shallow: true }),
    }),

    drop: (_item: DraggingSlideItem) => {
      const result: SlideDropResult = { ok: true };
      return result;
    },
  });

  return (
    <div
      className={`w-full flex items-center group cursor-pointer
        ${selected ? 'text-white' : 'text-icon-gray'}
      `}
      style={{
        boxShadow: isHover ? dropIndicatorBoxShadow : '',
      }}
      onClick={onSelect}
      ref={drop}
    >
      <div
        className={`flex-none w-1 h-6 rounded-lg ${
          selected ? 'bg-lp-red-002' : 'bg-transparent'
        }`}
      />
      <div className='ml-0.5 flex-1 overflow-hidden h-7 flex items-center hover:bg-light-gray p-0.5 pr-1.5 rounded-lg'>
        <div className='flex-none ml-1 w-6 h-full rounded-md bg-purple-600 flex justify-between items-center gap-2 text-white'>
          <AssessmentIcon className='p-1' />
        </div>
        <div
          className={`flex-1 truncate ml-2 text-sms font-medium ${
            selected ? 'text-white' : 'text-icon-gray group-hover:text-white'
          }`}
        >
          {game.name}
        </div>
        <div
          className='ml-1 flex items-center gap-1'
          onClick={(e) => e.stopPropagation()}
        >
          <AssessmentActionsButton
            selected={selected}
            onDelete={() => onDelete(game.id)}
            onRename={handleRename}
          />
          <button
            type='button'
            className='w-6 h-6 flex justify-center items-center'
            onClick={onExpand}
          >
            <ArrowDownIcon
              className={`w-4 h-4 fill-current ${
                expanded ? 'rotate-180' : ''
              } transition-transform`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function TrainingSlideGroupContainer(props: {
  store: GameEditorStore;
  selected: boolean;
  onDelete: (id: string) => void;
  onSelectBlock: (id: string, blockId: string | null) => void;
  onDeleteBlock: (blockId: string) => void;
  getStoreById: (id: string) => GameEditorStore | undefined;
  sectionNumber: number;
  isAssessment?: boolean;
}) {
  const {
    store,
    selected,
    onDelete,
    onSelectBlock,
    onDeleteBlock,
    sectionNumber,
    isAssessment,
  } = props;
  const { game, selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);

  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    // force open in case block length changes
    setExpanded(true);
  }, [blocks.length]);

  if (!game) return null;

  const border = isAssessment
    ? 'border border-gold-001'
    : 'border border-secondary';

  return (
    <div
      className={`w-full pl-0.5 pr-1.5 py-2 rounded-xl ${border} flex flex-col gap-3`}
    >
      <TrainingSlideGroupHeader
        game={game as Game}
        store={store}
        selected={selected && !selectedBlockId}
        onSelect={() => onSelectBlock(game.id, null)}
        expanded={expanded}
        onExpand={() => setExpanded(!expanded)}
        onDelete={onDelete}
        sectionNumber={sectionNumber}
      />

      {expanded && blocks.length > 0 && (
        <div className='w-full flex flex-col gap-3 text-white cursor-pointer'>
          {blocks.map((block, index) => (
            <TrainingSlideItem
              key={block.id}
              store={store}
              block={block as Block}
              index={index}
              selected={selected && selectedBlockId === block.id}
              onSelect={() => onSelectBlock(game.id, block.id)}
              onDelete={() => onDeleteBlock(block.id)}
              getStoreById={props.getStoreById}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function AssessmentWarningTooltip() {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
      trigger: 'hover',
      offset: [0, 8],
    });

  return (
    <div className='ml-2'>
      {/* Red circle with exclamation mark */}
      <div
        ref={setTriggerRef}
        className='h-5 w-5 rounded-full bg-red-500 flex items-center justify-center text-white'
      >
        <WarningIcon className='w-3 h-3 fill-current' />
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'bg-black text-white p-2 rounded-lg text-xs max-w-xs z-50 border border-gray-600',
          })}
        >
          Please add at least one interactive block to generate an assessment
        </div>
      )}
    </div>
  );
}

function AssessmentContainer(props: {
  store: GameEditorStore;
  selected: boolean;
  onDelete: (id: string) => void;
  onSelectBlock: (id: string, blockId: string | null) => void;
  onDeleteBlock: (blockId: string) => void;
  getStoreById: (id: string) => GameEditorStore | undefined;
}) {
  const { store, selected, onDelete, onSelectBlock, onDeleteBlock } = props;
  const { game, selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);

  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    // force open in case block length changes
    setExpanded(true);
  }, [blocks.length]);

  if (!game) return null;

  // Check if assessment container has any playable blocks
  const hasPlayableBlocks = blocks.some((block) =>
    blockTypePlayable(fromBlockType(block.type))
  );

  const border = 'border border-secondary';

  return (
    <div
      className={`w-full pl-0.5 pr-1.5 py-2 rounded-xl ${border} flex flex-col gap-3`}
    >
      <div className='flex items-center'>
        <div className='flex-1'>
          <AssessmentHeader
            game={game as Game}
            store={store}
            selected={selected && !selectedBlockId}
            onSelect={() => onSelectBlock(game.id, null)}
            expanded={expanded}
            onExpand={() => setExpanded(!expanded)}
            onDelete={onDelete}
          />
        </div>
        {!hasPlayableBlocks && <AssessmentWarningTooltip />}
      </div>

      {expanded && blocks.length > 0 && (
        <div className='w-full flex flex-col gap-3 text-white cursor-pointer'>
          {blocks.map((block, index) => (
            <TrainingSlideItem
              key={block.id}
              store={store}
              block={block as Block}
              index={index}
              selected={selected && selectedBlockId === block.id}
              onSelect={() => onSelectBlock(game.id, block.id)}
              onDelete={() => onDeleteBlock(block.id)}
              getStoreById={props.getStoreById}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export function TrainingEditorSidebar(props: {
  pack: DtoGamePack;
  stores: GameEditorStore[];
  onStoresChange: (stores: GameEditorStore[]) => void;
  selectedGameId: string | null;
  onSelectedGameIdChange: (id: string | null) => void;
  onSelectBlock: (id: string, blockId: string | null) => void;
  onPackChange: (pack: DtoGamePack) => void;
  analytics: ReturnType<typeof useLearningAnalytics>;
  setPack: (pack: DtoGamePack) => void;
}) {
  const {
    pack,
    stores,
    onStoresChange,
    selectedGameId,
    onSelectedGameIdChange,
    onPackChange,
    analytics,
    setPack,
  } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const blockLogicRefMap = useMemo(
    () => buildBlockRefMap(pack.logicSettings),
    [pack.logicSettings]
  );

  const assessmentStore = stores.find(
    (s) => s.state.game?.id === pack.assessmentSettings?.gameId
  );

  const handleNewSlideGroup = async () => {
    analytics.trackEditorNewSlideClicked({
      gamePackId: pack.id,
      slideType: 'slide group',
    });
    const resp = await apiService.game.create({
      name: `Untitled Slide Group`,
    });
    if (!resp.data.game) return;
    const store = new GameEditorStore();
    store.setEditingGame(resp.data.game, {
      blocks: [],
    });
    store.setSelectedBlockId(null);

    const newStores = [...stores];
    const position = stores.findIndex(
      (s) => s.state.game?.id === selectedGameId
    );
    const newPosition = position === -1 ? newStores.length : position + 1;

    newStores.splice(newPosition, 0, store);
    onStoresChange(newStores);
    onSelectedGameIdChange(resp.data.game.id);
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
    });
  };

  const handleNewAssessment = async () => {
    const resp = await apiService.gamePack.addAssessment(pack.id);
    if (!resp.data.gamePack) return;
    setPack(resp.data.gamePack);

    const assessmentGameId = resp.data.gamePack.assessmentSettings?.gameId;
    if (!assessmentGameId) return;

    // TODO(falcon): can this be bundled instead of having to fetch it?
    const gameResp = await apiService.game.getGameById(assessmentGameId);
    const game = gameResp.data.game;
    if (!game) return;

    const store = new GameEditorStore();
    store.setEditingGame(game, {
      blocks: [],
    });
    store.setSelectedBlockId(null);

    const newStores = [...stores];
    const newPosition = newStores.length;

    newStores.splice(newPosition, 0, store);

    onStoresChange(newStores);
    onSelectedGameIdChange(assessmentGameId);
  };

  const handleMoveSlideGroup = async (from: number, to: number) => {
    const newStores = [...stores];
    const store = newStores[from];
    newStores.splice(from, 1);
    newStores.splice(to, 0, store);
    onStoresChange(newStores);
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
    });
  };

  const triggerConfirmDelete = async (label: string) => {
    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
      prompt: (
        <>
          <ConfirmCancelModalHeading className='pt-3'>
            Are you sure?
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='text-sms font-normal py-2'>
            <>
              You are about to delete the {label}. This action cannot be undone.
            </>
          </ConfirmCancelModalText>
        </>
      ),
      confirmBtnLabel: 'Continue',
      autoFocus: 'cancel',
    });
    return result;
  };

  const handleDeleteAssessment = async (id: string) => {
    const store = stores.find((s) => s.state.game?.id === id);
    if (!store) return;

    const logicRefs = new Set(
      store.blockEditorStore.state.blocks.flatMap(
        (b) => blockLogicRefMap.get(b.id) ?? []
      )
    );

    let newLogic: ModelsLogicSettings | undefined;
    if (logicRefs.size > 0) {
      const { result } = await triggerModal({
        kind: 'confirm-cancel',
        boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
        prompt: (
          <>
            <ConfirmCancelModalHeading className='pt-3'>
              Are you sure?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='text-sms font-normal py-2'>
              You set up logic using one or more slides in this group. Deleting
              the group will remove the associated logic rules.
            </ConfirmCancelModalText>
          </>
        ),
        confirmBtnLabel: 'Continue',
        autoFocus: 'cancel',
      });
      if (result !== 'confirmed') return;
      newLogic =
        removeLogicRules(pack.logicSettings, Array.from(logicRefs)) ??
        undefined;
    } else if ((await triggerConfirmDelete('slide group')) === 'canceled') {
      return;
    }

    const newStores = stores.filter((s) => s.state.game?.id !== id);
    onStoresChange(newStores);
    if (selectedGameId === id) {
      onSelectedGameIdChange(null);
    }
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      logicSettings: newLogic,
    });
    const resp = await apiService.gamePack.removeAssessment(pack.id);
    onPackChange(resp.data.gamePack);
  };

  const handleDeleteSlideGroup = async (id: string) => {
    const store = stores.find((s) => s.state.game?.id === id);
    if (!store) return;

    const logicRefs = new Set(
      store.blockEditorStore.state.blocks.flatMap(
        (b) => blockLogicRefMap.get(b.id) ?? []
      )
    );

    let newLogic: ModelsLogicSettings | undefined;
    if (logicRefs.size > 0) {
      const { result } = await triggerModal({
        kind: 'confirm-cancel',
        boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
        prompt: (
          <>
            <ConfirmCancelModalHeading className='pt-3'>
              Are you sure?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='text-sms font-normal py-2'>
              You set up logic using one or more slides in this group. Deleting
              the group will remove the associated logic rules.
            </ConfirmCancelModalText>
          </>
        ),
        confirmBtnLabel: 'Continue',
        autoFocus: 'cancel',
      });
      if (result !== 'confirmed') return;
      newLogic =
        removeLogicRules(pack.logicSettings, Array.from(logicRefs)) ??
        undefined;
    } else if ((await triggerConfirmDelete('slide group')) === 'canceled') {
      return;
    }

    const newStores = stores.filter((s) => s.state.game?.id !== id);
    onStoresChange(newStores);
    if (selectedGameId === id) {
      onSelectedGameIdChange(null);
    }
    const resp = await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
      logicSettings: newLogic,
    });
    onPackChange(resp.data.gamePack);
  };

  const handleDeleteBlock = async (store: GameEditorStore, blockId: string) => {
    const logicRefs = blockLogicRefMap.get(blockId) ?? [];

    let newLogic: ModelsLogicSettings | undefined;
    if (logicRefs.length > 0) {
      const { result } = await triggerModal({
        kind: 'confirm-cancel',
        boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
        prompt: (
          <>
            <ConfirmCancelModalHeading className='pt-3'>
              Are you sure?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='text-sms font-normal py-2'>
              You set up logic using this slide. Deleting it will remove the
              associated logic rules.
            </ConfirmCancelModalText>
          </>
        ),
        confirmBtnLabel: 'Continue',
        autoFocus: 'cancel',
      });
      if (result !== 'confirmed') return;
      newLogic = removeLogicRules(pack.logicSettings, logicRefs) ?? undefined;
    } else if ((await triggerConfirmDelete('slide')) === 'canceled') {
      return;
    }

    // delete the block from the store.
    store.deleteBlock(blockId);

    if (newLogic) {
      // update the game pack with the new logic settings.
      const resp = await apiService.gamePack.update(pack.id, {
        changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
        logicSettings: newLogic,
      });
      onPackChange(resp.data.gamePack);
    }
  };

  const handleAddBlock = <T extends BlockType>(
    blockType: T,
    fields?: Partial<BlockTypeToFields<T>>
  ) => {
    const selectedStore = stores.find(
      (s) => s.state.game?.id === selectedGameId
    );
    if (!selectedStore || !selectedGameId) return;
    analytics.trackEditorNewSlideClicked({
      gamePackId: pack.id,
      slideType: blockType,
    });

    const position = selectedStore.blockEditorStore.state.blocks.findIndex(
      (b) => b.id === selectedStore.state.selectedBlockId
    );
    const newPosition =
      position === -1
        ? selectedStore.blockEditorStore.state.blocks.length
        : position + 1;
    selectedStore.createBlock(selectedGameId, newPosition, blockType, fields);
  };

  return (
    <div className='w-full h-full bg-main-layer rounded-xl p-2 flex flex-col gap-2'>
      {selectedGameId ? (
        <NewSlideButton
          onAddSlideGroup={
            selectedGameId === pack.assessmentSettings?.gameId
              ? undefined
              : handleNewSlideGroup
          }
          onAddAssessment={
            pack.assessmentSettings?.gameId ? undefined : handleNewAssessment
          }
          onAddBlock={handleAddBlock}
        />
      ) : (
        <button
          type='button'
          onClick={handleNewSlideGroup}
          className='w-full h-10 btn-secondary text-sms'
        >
          + New Slide Group
        </button>
      )}

      <div className='w-full flex-1 overflow-y-auto scrollbar flex flex-col gap-2'>
        <DragDropList
          items={stores
            .filter((s) => s.state.game?.id !== pack.assessmentSettings?.gameId)
            .map((s) => ({
              id: s.state.game?.id || '',
              store: s,
            }))}
          type='slide-groups'
          onMove={handleMoveSlideGroup}
          render={({ item: { store }, style, ref, drag, index }) => (
            <div className='w-full' style={style} ref={ref}>
              <div ref={drag}>
                <TrainingSlideGroupContainer
                  key={store.state.game?.id}
                  store={store}
                  selected={selectedGameId === store.state.game?.id}
                  onDelete={handleDeleteSlideGroup}
                  onSelectBlock={props.onSelectBlock}
                  onDeleteBlock={(blockId) => handleDeleteBlock(store, blockId)}
                  getStoreById={(id: string) =>
                    stores.find((s) => s.state.game?.id === id)
                  }
                  sectionNumber={index + 1}
                  isAssessment={
                    store.state.game?.id === pack.assessmentSettings?.gameId
                  }
                />
              </div>
            </div>
          )}
        />
        {assessmentStore && (
          <div className='w-full'>
            <DragDropList
              items={[
                {
                  id: 'assessment-slides',
                  slides: assessmentStore.blockEditorStore.state.blocks,
                },
              ]}
              type='assessment-slides'
              render={({ style, ref }) => (
                <div className='w-full' style={style} ref={ref}>
                  <AssessmentContainer
                    key={assessmentStore.state.game?.id}
                    store={assessmentStore}
                    selected={selectedGameId === assessmentStore.state.game?.id}
                    onDelete={handleDeleteAssessment}
                    onSelectBlock={props.onSelectBlock}
                    onDeleteBlock={(blockId) =>
                      handleDeleteBlock(assessmentStore, blockId)
                    }
                    getStoreById={(id: string) =>
                      stores.find((s) => s.state.game?.id === id)
                    }
                  />
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
