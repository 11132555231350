import { type Editor, mergeAttributes, Node } from '@tiptap/core';
import {
  NodeViewContent,
  type NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { useMemo, useRef, useState } from 'react';
import Select, { type GroupBase, type SingleValue } from 'react-select';

import { type DtoPersonality } from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { Role } from '../../../types';
import { fromMediaDTO } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { useUser } from '../../UserContext';
import { sharedPersonalitySelectComponents } from '../PersonalitySelect';
import { usePersonalities } from '../usePersonalities';

const ENTRY_DECORATION = Object.freeze({});

export type EntryOptions = {
  defaultPersonalityId: string;
  singlePersonality?: boolean;
  onChange: (editor: Nullable<Editor>) => void;
  coursePersonalityIds?: string[];
};

// This is the react view for the dialogue entry in the RTE, which is needed to pull in the PersonalitySelector. It's
// quite simple except that once react-select pops up, and the user interacts with it, the editor will fire a blur
// event. Changing the personality on an entry needs to trigger a change to the caller, so we manually trigger it here.
// It's a pain to get the callback passed in here, but it's accomplished by way of the extension options.
export function EntryComponent(props: NodeViewProps) {
  const { editor } = props;
  const ext = props.extension as { options: EntryOptions };

  const decoration = props.decorations.find(
    (d) => d.spec.map === ENTRY_DECORATION
  );
  const index = decoration?.spec.index ?? -1;
  const forceShow = decoration?.spec.forceShow ?? false;

  const handleChange = useLiveCallback((p: DtoPersonality) => {
    props.updateAttributes({
      personalityId: p.id,
    });
    ext.options.onChange(editor);
  });

  const personalitySelectable = !ext.options.singlePersonality || index === 0;

  return (
    <NodeViewWrapper className='relative w-full min-h-7 flex items-center group'>
      {personalitySelectable && (
        <div className='absolute top-0 left-0' contentEditable={false}>
          <PersonalitySelector
            onChange={handleChange}
            value={props.node.attrs.personalityId}
            forceShow={forceShow}
            coursePersonalityIds={ext.options.coursePersonalityIds}
          />
        </div>
      )}
      <NodeViewContent className='entry-placeholder text-sms flex-1 pl-9' />
    </NodeViewWrapper>
  );
}

function PersonalitySelector(props: {
  value?: string | null;
  onChange: (value: DtoPersonality) => void;
  forceShow?: boolean;
  coursePersonalityIds?: string[];
}): JSX.Element {
  const user = useUser();
  const { data, isLoading } = usePersonalities();

  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoPersonality>({
        override: {
          control: {
            background: '#232325',
            borderRadius: '4px',
            cursor: 'pointer',
            width: '100%',
            minHeight: 'auto',
            padding: '3px 1px',
          },
          menu: {
            width: '100%',
          },
          valueContainer: {
            width: '100%',
            padding: '0',
          },
          dropdownIndicator: {
            padding: '0',
          },
          input: {
            padding: '0 2px',
            margin: '0',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    const isAdmin = user.role === Role.Admin;
    const options = data ?? [];
    const coursePersonalityIds = props.coursePersonalityIds ?? [];
    if (coursePersonalityIds.length > 0) {
      // let's split them.
      const allOptions: DtoPersonality[] = [];
      const courseOptions: DtoPersonality[] = [];
      for (const o of options) {
        if (coursePersonalityIds.includes(o.id)) {
          courseOptions.push(o);
        } else {
          allOptions.push(o);
        }
      }
      if (courseOptions.length > 0) {
        return [
          {
            label: 'Used in this Course',
            options: courseOptions,
          },
          {
            label: 'All',
            options: isAdmin
              ? allOptions
              : allOptions.filter((o) => o.publiclyListed),
          },
        ];
      }
    }
    return isAdmin ? options : options.filter((o) => o.publiclyListed);
  }, [user.role, data, props.coursePersonalityIds]);

  const selectedValue = useMemo(() => {
    if (!props.value) return null;
    return data?.find((item) => item.id === props.value) ?? null;
  }, [data, props.value]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = useLiveCallback(() => {
    setHovered(true);
  });
  const handleMouseLeave = useLiveCallback(() => {
    if (menuOpen) return;
    setHovered(false);
  });
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => {
    setMenuOpen(false);
    setHovered(false);
  });
  const handleSingleChange = (option: SingleValue<DtoPersonality>) => {
    if (!option) return;
    props.onChange(option);
    setMenuOpen(false);
    setHovered(false);
  };
  const opacity =
    hovered || menuOpen || props.forceShow
      ? 'opacity-100'
      : 'group-hover:opacity-60 opacity-0';

  return (
    <div
      ref={ref}
      className={`relative transition-opacity select-none ${opacity}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`p-[3px] border border-transparent`}>
        <ProfileImage personality={selectedValue} />
      </div>
      <div
        className={`
          absolute top-0 left-0
           ${hovered ? 'opacity-100 w-42' : 'opacity-0 w-14'}
           transition-size z-50
        `}
      >
        {hovered && (
          <Select<DtoPersonality, false, GroupBase<DtoPersonality>>
            styles={styles}
            options={options}
            value={selectedValue}
            getOptionLabel={(option) => option.displayLabel}
            getOptionValue={(option) => option.id}
            onChange={handleSingleChange}
            isLoading={isLoading}
            isSearchable
            components={{
              IndicatorSeparator: () => null,
              ...sharedPersonalitySelectComponents,
            }}
            menuPosition='fixed'
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

export function ProfileImage(props: { personality: Nullable<DtoPersonality> }) {
  const profileImageUrl = useMemo(() => {
    return MediaUtils.PickMediaUrl(
      fromMediaDTO(props.personality?.profileImage?.media),
      {
        priority: [MediaFormatVersion.SM],
      }
    );
  }, [props.personality?.profileImage?.media]);

  return (
    <div className='flex-none w-5 h-5 rounded-full bg-gray-500 overflow-hidden'>
      {profileImageUrl && (
        <img
          src={profileImageUrl}
          alt={props.personality?.displayLabel}
          className='w-full h-full object-cover'
        />
      )}
    </div>
  );
}

export const DialogueEditorEntry = Node.create<EntryOptions>({
  name: 'entry',
  content: 'inline*',
  addAttributes() {
    return {
      id: uuidv4(),
      personalityId: this.options.defaultPersonalityId,
    };
  },
  parseHTML() {
    return [
      {
        tag: 'entry',
        getAttrs(node) {
          return {
            id: node.dataset.id,
            personalityId: node.dataset.personalityId,
            index: parseInt(node.dataset.index || '0'),
          };
        },
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'entry',
      mergeAttributes(
        {
          'data-id': node.attrs.id,
          'data-personality-id': node.attrs.personalityId,
          'data-index': node.attrs.index,
        },
        HTMLAttributes
      ),
      0,
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(EntryComponent);
  },
  addProseMirrorPlugins() {
    const defaultPersonalityId = this.options.defaultPersonalityId;
    return [
      // the purpose of this plugin is to decorate the first entry of a personality with `forceShow`.
      // we `forceShow` the first entry, and subsequent entries with the same personality will be hidden.
      new Plugin({
        props: {
          decorations(state) {
            const decorations: Decoration[] = [];
            let currentPersonalityId: Nullable<string> = null;
            let index = 0;

            state.doc.descendants((node, pos) => {
              if (node.type.name === 'entry') {
                decorations.push(
                  Decoration.node(
                    pos,
                    pos + node.nodeSize,
                    {},
                    {
                      map: ENTRY_DECORATION,
                      forceShow:
                        node.attrs.personalityId !== currentPersonalityId,
                      index,
                    }
                  )
                );

                if (node.attrs.personalityId !== currentPersonalityId) {
                  currentPersonalityId = node.attrs.personalityId;
                }

                index++;
                return false;
              }
            });

            return DecorationSet.create(state.doc, decorations);
          },
        },
        // the purpose of this transaction is to ensure that every entry has a personalityId.
        // if a transaction on the editor, for instance, select all and delete, removes all entries,
        // we want to be sure the resulting entry has a personalityId set.
        appendTransaction(_, __, state) {
          let lastPersonalityId = defaultPersonalityId;
          const tx = state.tr;
          state.doc.descendants((node, pos) => {
            if (node.type.name === 'entry') {
              if (!node.attrs.personalityId) {
                tx.setNodeAttribute(pos, 'personalityId', lastPersonalityId);
              } else {
                lastPersonalityId = node.attrs.personalityId;
              }
              return false;
            }
          });
          return tx;
        },
      }),
    ];
  },
});
