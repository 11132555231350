import './dialogueEditor.css';

import {
  type Content,
  type Editor,
  type EditorEvents,
  Node,
} from '@tiptap/core';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { History } from '@tiptap/extension-history';
import { Placeholder } from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import { type Node as ProsemirrorNode } from 'prosemirror-model';
import { useEffect, useMemo, useRef, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';

import {
  EnumsDialogueGenerationType,
  EnumsDialogueOfflineRenderStatus,
  type ModelsDialogue,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useMedia } from '../../../hooks/useMedia';
import { apiService } from '../../../services/api-service';
import { RoleUtils } from '../../../types';
import { uuidv4 } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { ActionSheet } from '../../ActionSheet';
import { AIWriterIcon } from '../../icons/AIIcon';
import { LockIcon } from '../../icons/LockIcon';
import { NoRenderIcon, RenderIcon } from '../../icons/RenderIcon';
import { UnlockIcon } from '../../icons/UnlockIcon';
import { Loading } from '../../Loading';
import { useTrainingEditorCoursePersonalityIds } from '../../Training/Editor/TrainingEditorControlAPI';
import { useUser } from '../../UserContext';
import { findDefaultPersonality, usePersonalities } from '../usePersonalities';
import { VoiceOverUtils } from '../utils';
import {
  DialogueEditorEntry as Entry,
  type EntryOptions,
} from './DialogueEditorEntry';
import {
  DialogueEditorMark as Mark,
  type MarkOptions,
} from './DialogueEditorMark';
import { AddImageMarkButton } from './DialogueEditorMarkImage';
import { AddTutorQuestionMarkButton } from './DialogueEditorMarkTutorQuestion';
import { DialogueImportButton } from './DialogueImportModal';
import { type DialogueMarkerType } from './types';
import { DialogueUtils } from './utils';

type State = {
  mediaId: string | null;
};

const state = proxy<State>({
  mediaId: null,
});

export function DialogueMediaPreview() {
  const { mediaId } = useSnapshot(state);

  const { media } = useMedia(mediaId);

  const url = MediaUtils.PickMediaUrl(media, {
    priority: ImagePickPriorityHighToLow,
  });

  if (!url)
    return (
      <div className='w-full h-full flex items-center justify-center text-icon-gray '>
        No image applied to this text
      </div>
    );
  return <img src={url} alt={''} className='w-full h-full object-cover' />;
}

export interface DialogueEditorProps {
  title?: string;
  subtitle?: string;
  value: ModelsDialogue | null | undefined;
  onChange: (val: ModelsDialogue) => void;
  offlineRendering: boolean;
  singlePersonality?: boolean;
  enabledMarkTypes?: DialogueMarkerType[];
  importEnabled?: boolean;
  editorContainerStyles?: {
    background?: string;
    border?: string;
  };
}

export function DialogueEditor(props: DialogueEditorProps) {
  const {
    title,
    subtitle,
    value,
    onChange,
    offlineRendering,
    singlePersonality,
    enabledMarkTypes = [],
    editorContainerStyles,
    importEnabled = false,
  } = props;

  const isAdmin = RoleUtils.isAdmin(useUser());
  const coursePersonalityIds = useTrainingEditorCoursePersonalityIds();
  const { data: personalities, isLoading: loadingPersonalities } =
    usePersonalities();
  const personalitiesMap = useMemo(() => {
    return new Map(personalities?.map((p) => [p.id, p]));
  }, [personalities]);
  const defaultPersonality = useMemo(() => {
    if (!personalities) return undefined;
    if (coursePersonalityIds && coursePersonalityIds.length > 0) {
      for (const pid of coursePersonalityIds) {
        const p = personalitiesMap.get(pid);
        if (p) {
          return p;
        }
      }
    }
    return isAdmin
      ? personalities.at(0)
      : findDefaultPersonality(personalities);
  }, [coursePersonalityIds, isAdmin, personalities, personalitiesMap]);
  const defaultPersonalityId = defaultPersonality?.id || '';

  const handleChange = useLiveCallback((val: ModelsDialogue) => {
    let newDialogue = DialogueUtils.EnsurePersonalities(
      val,
      personalitiesMap,
      defaultPersonalityId
    );
    if (offlineRendering) {
      newDialogue.entries = newDialogue.entries.map((entry) => {
        const personality = personalitiesMap.get(entry.personalityId);
        if (!personality?.avatarId) return entry;
        return {
          ...entry,
          generationType:
            EnumsDialogueGenerationType.DialogueGenerationTypeOffline,
          offlineRender: {
            status:
              EnumsDialogueOfflineRenderStatus.DialogueOfflineRenderStatusNotStarted,
          },
        };
      });
    }
    if (newDialogue.entries.every((e) => e.script === '')) {
      newDialogue = { entries: [] };
    }
    onChange(newDialogue);
  });

  const containsRenderedAvatars = VoiceOverUtils.ContainsRenderedAvatars(value);
  const requiresRender = VoiceOverUtils.IsDialogueRequiresRender(value);
  const isRendering = VoiceOverUtils.IsDialogueRendering(value);
  const lockable = containsRenderedAvatars || isRendering;
  const [locked, setLocked] = useState(
    () => containsRenderedAvatars || isRendering
  );

  useEffect(() => {
    if (!isRendering) return;
    setLocked(true);
  }, [isRendering]);

  const handleBlur = useLiveCallback(({ editor }: EditorEvents['blur']) => {
    handleChange(fromEditorToDialogue(editor.state.doc));
  });

  const handleEditorChange = useLiveCallback((editor: Nullable<Editor>) => {
    if (!editor) return;
    handleChange(fromEditorToDialogue(editor.state.doc));
  });

  const extensions = useMemo(() => {
    const markOptions: MarkOptions = {
      enabledTypes: enabledMarkTypes,
      onChange: handleEditorChange,
    };

    const entryOptions: EntryOptions = {
      defaultPersonalityId: defaultPersonality?.id || '',
      onChange: handleEditorChange,
      singlePersonality,
      coursePersonalityIds,
    };

    return [
      Dialogue,
      Entry.configure(entryOptions),
      Mark.configure(markOptions),
      Text,
      Dropcursor,
      History,
      Placeholder.configure({
        placeholder: '',
        showOnlyWhenEditable: false,
        showOnlyCurrent: false,
      }),
    ];
  }, [
    enabledMarkTypes,
    handleEditorChange,
    defaultPersonality?.id,
    singlePersonality,
    coursePersonalityIds,
  ]);

  const editor = useEditor({
    extensions,
    content: fromDialogueToEditor(value, defaultPersonalityId),
    editorProps: {
      attributes: {
        class: 'w-full h-full appearance-none outline-none space-y-2',
      },
    },
    onCreate: ({ editor }) => {
      // Initialize with the first image mark
      const imageMarks = findImageMarksWithPositions(editor);
      state.mediaId = imageMarks[0]?.mediaId ?? null;
    },
    onBlur: handleBlur,
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    onSelectionUpdate: ({ editor }) => {
      const { from } = editor.state.selection;
      const imageMarks = findImageMarksWithPositions(editor);
      const lastImage = imageMarks.findLast((m) => m.pos <= from);
      state.mediaId = lastImage?.mediaId ?? null;
    },
  });

  const afterFirstRender = useRef(false);
  useEffect(() => {
    // Optimization: Skip setting the content on the first render since it's
    // already been set due to initialization!
    if (!afterFirstRender.current) {
      afterFirstRender.current = true;
      return;
    }

    // If the content changes due to an incoming prop change (e.g. backend
    // server update), consider it the newest content and update the editor.
    // NOTE: this assumes that `content` remains "uncontrolled" e.g. not locked
    // into a useState variable.
    const editorDialogue = fromEditorToDialogue(editor.state.doc);
    if (!!value && DialogueUtils.IsDialogueEqual(value, editorDialogue)) return;

    editor.commands.setContent(
      fromDialogueToEditor(value, defaultPersonalityId)
    );
  }, [editor, value, defaultPersonalityId]);

  const {
    call: generateImages,
    state: {
      state: { isRunning: isGeneratingImages },
    },
  } = useLiveAsyncCall(async () => {
    if (!value) return;
    const dialogueSchema = DialogueUtils.DialogueToAISchema(value);
    const response = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'dialogue/add-images',
      variables: {
        dialogue: JSON.stringify(dialogueSchema),
      },
    });
    const args = response.data.toolCalls.at(0)?.args;
    if (!args) {
      throw new Error('No tool calls returned');
    }
    const dialogue = DialogueUtils.AISchemaToDialogue(args);
    const filledDialogue = await DialogueUtils.FillDialogueImages(dialogue);
    handleChange(filledDialogue);
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <header className='pr-3 flex-none flex justify-between'>
        <div className='flex items-center gap-2'>
          <div className='flex flex-col gap-1'>
            <p className='text-base text-white font-bold'>
              {title || 'Voice Over Script'}
            </p>
            {subtitle && <p className='text-3xs text-icon-gray'>{subtitle}</p>}
          </div>

          {lockable && (
            <div className='relative mr-1 group'>
              <button
                type='button'
                className='w-5 h-5 text-white disabled:text-icon-gray disabled:cursor-not-allowed flex items-center justify-center'
                onClick={() => setLocked(!locked)}
                disabled={isRendering}
              >
                {locked ? (
                  <LockIcon className='w-3.5 h-3.5 fill-current' />
                ) : (
                  <UnlockIcon className='w-3.5 h-3.5 fill-current' />
                )}
              </button>

              <div className='absolute top-0 right-full z-5 hidden group-hover:block'>
                <div className='w-48 bg-black border border-secondary text-white rounded-lg px-4 py-2 text-3xs'>
                  {isRendering
                    ? 'This script is locked while the script avatars are rendering'
                    : 'Editing a rendered avatar script will require another render'}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='flex items-center gap-4'>
          {enabledMarkTypes.includes('image') && (
            <AddImageMarkButton editor={editor} />
          )}
          {enabledMarkTypes.includes('image') && (
            <ActionSheet
              actions={[
                {
                  key: 'add-images',
                  kind: 'button',
                  text: 'Add Images to Script',
                  onClick: () => generateImages(),
                  disabled:
                    isGeneratingImages ||
                    !value?.entries
                      ?.map((e) => e.script)
                      ?.join('')
                      ?.trim().length,
                },
              ]}
              placement='bottom'
              optionsChildren={
                <AIWriterIcon className='w-4 h-4 fill-current' />
              }
              containerClassName='w-4 h-4 text-icon-gray hover:text-white'
            />
          )}
          {enabledMarkTypes.includes('tutor-question') && (
            <AddTutorQuestionMarkButton editor={editor} />
          )}
          {importEnabled && isAdmin && (
            <DialogueImportButton
              editor={editor}
              onChange={(dialogue) => handleChange(dialogue)}
            />
          )}
        </div>
      </header>

      <main
        className={`relative w-full ${
          editorContainerStyles?.background ?? 'bg-dark-gray'
        } ${
          editorContainerStyles?.border || ''
        } rounded-xl flex flex-col px-2 py-3 ${
          lockable && locked ? 'opacity-40 pointer-events-none' : ''
        }`}
      >
        {loadingPersonalities ? (
          <Loading
            text=''
            imgClassName='w-5 h-5'
            containerClassName='w-full h-full flex items-center justify-center'
          />
        ) : !defaultPersonality?.id ? (
          <div className='w-full h-full flex items-center justify-center px-2 text-sms text-red-002'>
            No personalities found
          </div>
        ) : (
          editor && <EditorContent editor={editor} />
        )}

        {isGeneratingImages && (
          <div className='absolute inset-0 bg-lp-black-004 flex items-center justify-center'>
            <Loading
              text='This might take a while, please be patient...'
              imgClassName='w-5 h-5'
              containerClassName='w-full h-full flex items-center justify-center text-sm'
            />
          </div>
        )}
      </main>

      <footer className='flex-none w-full px-2 flex items-center justify-between text-sms'>
        <div></div>
        {isRendering ? (
          <div className='text-icon-gray flex items-center gap-1'>
            <RenderIcon className='w-4 h-4 fill-current' />
            Rendering Avatar ...
          </div>
        ) : requiresRender ? (
          <div className='text-tertiary flex items-center gap-1'>
            <RenderIcon className='w-4 h-4 fill-current' />
            Avatar Render Required
          </div>
        ) : (
          <div className='text-icon-gray flex items-center gap-1'>
            <NoRenderIcon className='w-4 h-4 fill-current' />
            No Avatar Render Required
          </div>
        )}
      </footer>
    </div>
  );
}

// this is the core editor schema for ModelsDialogue.
const Dialogue = Node.create({
  name: 'dialogue',
  topNode: true,
  content: 'entry+',
  parseHTML() {
    return [{ tag: 'dialogue' }];
  },
});

const Text = Node.create({
  name: 'text',
  group: 'inline',
  inline: true,
});

function fromEditorToDialogue(doc: ProsemirrorNode): ModelsDialogue {
  const entries: ModelsDialogueEntry[] = [];

  let currentPersonalityId: Nullable<string> = null;
  let scriptLines: string[] = [];
  for (let i = 0; i < doc.childCount; i++) {
    const node = doc.child(i);
    if (node.type.name === 'entry') {
      if (node.attrs.personalityId !== currentPersonalityId) {
        if (currentPersonalityId) {
          entries.push({
            id: uuidv4(),
            generationType:
              EnumsDialogueGenerationType.DialogueGenerationTypeClient,
            personalityId: currentPersonalityId,
            script: scriptLines.join('\n'),
          });
        }
        currentPersonalityId = node.attrs.personalityId;
        scriptLines = [];
      }
      const textContent: string[] = [];
      node.descendants((child) => {
        if (child.isText) {
          textContent.push(child.textContent);
        } else if (child.type.name === 'mark') {
          const { type, name, question, finishCriteria } = child.attrs;

          // Create XML safely using DOM methods
          const markElement = document.createElement('mark');
          markElement.setAttribute('type', type || 'trigger');
          markElement.setAttribute('name', name);

          if (type === 'tutor-question') {
            markElement.setAttribute('question', question);
            markElement.setAttribute('finishCriteria', finishCriteria);
          }

          // Convert to XML string (self-closing tag)
          const xmlString = markElement.outerHTML.replace(/><\/mark>$/, ' />');
          textContent.push(xmlString);
        }
      });
      scriptLines.push(textContent.join(''));
    }
  }
  if (currentPersonalityId) {
    entries.push({
      id: uuidv4(),
      generationType: EnumsDialogueGenerationType.DialogueGenerationTypeClient,
      personalityId: currentPersonalityId,
      script: scriptLines.join('\n'),
    });
  }
  return { entries };
}

export function fromDialogueToEditor(
  dialogue: Nullable<ModelsDialogue>,
  defaultPersonalityId: string
): Content {
  const doc = document.createElement('dialogue');

  for (const entry of dialogue?.entries ?? []) {
    entry.script.split('\n').forEach((line) => {
      const e = document.createElement('entry');
      e.dataset.id = entry.id;
      e.dataset.personalityId = entry.personalityId;

      // innerHTML doesn't like self closing tags...
      const processedLine = line.replaceAll(
        /<mark\s+(.*?)\s*\/>/g,
        '<mark $1></mark>'
      );

      e.innerHTML = processedLine;
      doc.appendChild(e);
    });
  }

  if (doc.childElementCount === 0) {
    const e = document.createElement('entry');
    e.dataset.id = uuidv4();
    e.dataset.personalityId = defaultPersonalityId;
    doc.appendChild(e);
  }
  return doc.outerHTML;
}

// Helper function to find all image marks with their positions
function findImageMarksWithPositions(editor: Editor) {
  const imageMarks: Array<{ pos: number; mediaId: string }> = [];
  editor.state.doc.descendants((node, pos) => {
    if (node.type.name === 'mark' && node.attrs.type === 'image') {
      imageMarks.push({ pos, mediaId: node.attrs.name });
    }
    return true;
  });
  return imageMarks.sort((a, b) => a.pos - b.pos);
}
