import isEqual from 'lodash/isEqual';
import { useMemo, useState } from 'react';
import Select, { components, type SingleValue } from 'react-select';

import { type ModelsLogicSettings } from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';
import {
  type BlockOutputDesc,
  type BlockOutputSchema,
  type BlockOutputsDesc,
} from '@lp-lib/game/src/block-outputs';
import {
  type BlockLogic,
  type EnumComparison,
  Logic,
  type LogicAction,
  type LogicArg,
  type LogicCondition,
  type LogicRule,
  type LogicSettings,
  LogicSettingsSchema,
  type NumberComparison,
  type StringComparison,
} from '@lp-lib/game/src/logic';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import { BlockKnifeUtils } from '../../Game/Blocks/Shared';
import { getOutputSchema as getHiddenPictureOutputSchema } from '../../GameV2/blocks/HiddenPicture/outputs';
import { getOutputSchema as getMatchOutputSchema } from '../../GameV2/blocks/Match/outputs';
import { getOutputSchema as getMultipleChoiceOutputSchema } from '../../GameV2/blocks/MultipleChoice/outputs';
import { getOutputSchema as getQuestionOutputSchema } from '../../GameV2/blocks/Question/outputs';
import { getOutputSchema as getRoleplayOutputSchema } from '../../GameV2/blocks/Roleplay/outputs';
import { getOutputSchema as getScenarioOutputSchema } from '../../GameV2/blocks/Scenario/outputs';
import { SparkBlockIcon } from '../../icons/Block';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { PlusIcon } from '../../icons/PlusIcon';
import { TrainingEditorUtils } from './utils';

export function LogicEditor(props: {
  defaultValue: Nullable<ModelsLogicSettings>;
  onSave: (value: ModelsLogicSettings) => void;
  onCancel: () => void;
  minigameNames: Map<string, string>;
  blocks: Block[];
  blockId?: Nullable<string>;
}) {
  const { defaultValue, blocks, blockId, onCancel, onSave, minigameNames } =
    props;
  const [error, setError] = useState<Error | null>(null);
  const [value, setValue] = useState<LogicSettings>(() => {
    if (!defaultValue) return { blockLogic: {} };

    const r = LogicSettingsSchema.safeParse(defaultValue);
    if (!r.success) {
      console.error(r.error);
      setError(r.error);
    }
    return r.success ? r.data : { blockLogic: {} };
  });

  const handleBlockLogicChange = useLiveCallback(
    (blockId: string, logic: BlockLogic) => {
      const next = { ...value.blockLogic };
      if (logic.rules.length === 0) {
        delete next[blockId];
      } else {
        next[blockId] = logic;
      }
      setValue({ blockLogic: next });
    }
  );

  const handleDeleteAll = useLiveCallback(() => {
    if (blockId) {
      const next = { ...value.blockLogic };
      delete next[blockId];
      setValue({ blockLogic: next });
    } else {
      setValue({ blockLogic: {} });
      setError(null);
    }
  });

  const body = useMemo(() => {
    if (error) {
      return (
        <div className='bg-main-layer rounded-xl  w-full h-20 flex items-center justify-center text-center text-sm text-red-002'>
          Failed to load logic settings. Please try again.
        </div>
      );
    }

    if (blockId) {
      const block = blocks.find((b) => b.id === blockId);
      if (block) {
        return (
          <BlockLogicEditor
            value={value.blockLogic[blockId]}
            onChange={(v) => handleBlockLogicChange(blockId, v)}
            block={block}
            blocks={blocks}
            minigameNames={minigameNames}
          />
        );
      }
    }

    const result = blocks.map((block) => {
      return (
        <BlockLogicEditor
          key={block.id}
          value={value.blockLogic[block.id]}
          onChange={(v) => handleBlockLogicChange(block.id, v)}
          block={block}
          blocks={blocks}
          minigameNames={minigameNames}
          minimize
        />
      );
    });

    if (result.length === 0) {
      return (
        <div className='bg-main-layer rounded-xl w-full h-20 flex items-center justify-center text-center text-sm text-icon-gray'>
          No slides in this pack support logic
        </div>
      );
    }
    return result;
  }, [
    error,
    blockId,
    blocks,
    value.blockLogic,
    minigameNames,
    handleBlockLogicChange,
  ]);

  const handleSave = useLiveCallback(() => {
    onSave(value);
  });

  return (
    <div className='flex flex-col border border-secondary bg-black rounded-xl px-1 w-5/6 xl:w-1/2 min-h-0 max-h-[80%] overflow-hidden text-white'>
      <div className='flex-none w-full px-4 pt-5 pb-2'>
        <div className='font-bold text-lg'>Logic</div>
      </div>

      <div className='w-full flex-1 min-h-0 px-4 pt-2 pb-4 mb-4 overflow-y-auto scrollbar space-y-3'>
        {body}
      </div>

      <div className='mt-auto w-full flex items-center justify-between gap-4 px-5 pb-3'>
        <button
          type='button'
          className='btn flex justify-center items-center text-red-002 gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
          onClick={handleDeleteAll}
        >
          <DeleteIcon />
          Delete All
        </button>
        <div className='flex-none flex items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

function BlockLogicEditor(props: {
  value: Nullable<BlockLogic>;
  onChange: (value: BlockLogic) => void;
  block: Block;
  blocks: Block[];
  minigameNames: Map<string, string>;
  minimize?: boolean; // true if the nux state should be minimized
}) {
  const rules = props.value?.rules ?? [];

  const outputsDesc = useMemo(
    () => getBlockOutputs(props.block),
    [props.block]
  );

  const handleAddLogic = useLiveCallback(() => {
    const output = Object.values(outputsDesc).at(0);
    if (!output) return;

    const newRule: LogicRule = {
      id: uuidv4(),
      trigger: Logic.trigger.onEnd(),
      condition: defaultComparison(
        Logic.args.output(props.block.id, output.name),
        output.schema
      ),
      actions: [
        {
          type: 'jump',
          props: {
            toBlockId: props.blocks[0].id,
          },
        },
      ],
    };

    const next = [...rules];
    if (next.at(-1) && isAlwaysFallback(next.at(-1))) {
      next.splice(next.length - 1, 0, newRule);
    } else {
      next.push(newRule);
    }
    props.onChange({ rules: next });
  });

  const handleAddAlwaysFallback = useLiveCallback((rule: LogicRule) => {
    const next = [...rules];
    next.push(rule);
    props.onChange({ rules: next });
  });

  const handleRuleChange = useLiveCallback((rule: LogicRule) => {
    const next = [...rules];
    const index = next.findIndex((r) => r.id === rule.id);
    if (index === -1) return;
    next[index] = rule;
    props.onChange({ rules: next });
  });

  const handleRemoveRule = useLiveCallback((ruleId: string) => {
    const next = [...rules];
    const index = next.findIndex((r) => r.id === ruleId);
    if (index === -1) return;
    next.splice(index, 1);
    props.onChange({ rules: next });
  });

  const body = useMemo(() => {
    const result = [];
    let pushedAlwaysFallback = false;
    const rules = props.value?.rules ?? [];
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      if (i === rules.length - 1 && isAlwaysFallback(rule)) {
        result.push(
          <AlwaysFallback
            value={rule}
            onChange={(v) => {
              if (!v) handleRemoveRule(rule.id);
              else handleRuleChange(v);
            }}
            blocks={props.blocks}
            minigameNames={props.minigameNames}
            numRules={result.length}
          />
        );
        pushedAlwaysFallback = true;
        continue;
      }
      result.push(
        <LogicRuleEditor
          key={rule.id}
          value={rule}
          onChange={handleRuleChange}
          onRemove={() => handleRemoveRule(rule.id)}
          blockOutputs={outputsDesc}
          blocks={props.blocks}
          currentBlockId={props.block.id}
          minigameNames={props.minigameNames}
        />
      );
    }

    if (!pushedAlwaysFallback) {
      result.push(
        <AlwaysFallback
          value={null}
          onChange={(v) => {
            if (!v) return;
            handleAddAlwaysFallback(v);
          }}
          blocks={props.blocks}
          minigameNames={props.minigameNames}
          numRules={result.length}
        />
      );
    }
    return result;
  }, [
    props.value?.rules,
    props.blocks,
    props.block.id,
    props.minigameNames,
    handleRuleChange,
    outputsDesc,
    handleRemoveRule,
    handleAddAlwaysFallback,
  ]);

  const numOutputs = Object.values(outputsDesc).length;
  const numRules = rules.length;
  const minigameName = props.minigameNames.get(props.block.gameId);

  return (
    <div className='bg-main-layer rounded-xl w-full'>
      <div className='w-full flex items-center justify-between gap-4 border-b border-black px-4 py-3'>
        <div className='flex-1 min-w-0 flex items-center gap-2'>
          <SparkBlockIcon blockType={props.block.type} />
          <div className='min-w-0 flex-1 flex flex-col item-center'>
            <div className='text-white text-sms font-bold truncate'>
              {TrainingEditorUtils.BlockTitle(props.block)}
            </div>
            {minigameName && (
              <div className='text-secondary text-xs truncate'>
                {minigameName}
              </div>
            )}
          </div>
        </div>
        {numOutputs > 0 && (
          <div className='flex-none flex items-center gap-2'>
            <div className='text-icon-gray text-sm  '>
              {numRules} {numRules === 1 ? 'Rule' : 'Rules'}
            </div>
            <button
              type='button'
              className='btn flex justify-center items-center text-primary gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
              onClick={handleAddLogic}
            >
              <PlusIcon />
              Add Logic
            </button>
          </div>
        )}
      </div>

      {body && <div className='space-y-3 p-2'>{body}</div>}
    </div>
  );
}

function LogicRuleEditor(props: {
  value: LogicRule;
  onChange: (value: LogicRule) => void;
  onRemove: () => void;
  blockOutputs: BlockOutputsDesc;
  blocks: Block[];
  currentBlockId: string;
  minigameNames: Map<string, string>;
}) {
  const {
    value,
    onChange,
    onRemove,
    blockOutputs,
    blocks,
    currentBlockId,
    minigameNames,
  } = props;
  return (
    <div className='flex flex-col gap-1 px-5 py-4 bg-layer-002 rounded-md text-sm text-white'>
      <ConditionEditor
        value={value.condition}
        onChange={(v) => onChange({ ...value, condition: v })}
        blockOutputs={blockOutputs}
        currentBlockId={currentBlockId}
      />
      <div className='w-full border-t border-secondary my-2' />
      <ActionEditor
        value={value.actions[0]}
        onChange={(v) => onChange({ ...value, actions: [v] })}
        blocks={blocks}
        minigameNames={minigameNames}
      />
      <div className='w-full flex justify-end gap-2 pt-2'>
        <button
          type='button'
          className='btn flex justify-center items-center text-red-002 gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
          onClick={onRemove}
        >
          <DeleteIcon />
          Delete Rule
        </button>
      </div>
    </div>
  );
}

// conditions

const stringComparisonOptions: Option<StringComparison>[] = [
  { value: 'equal', label: 'is equal to' },
  { value: 'notEqual', label: 'is not equal' },
  { value: 'beginsWith', label: 'begins with' },
  { value: 'endsWith', label: 'ends with' },
  { value: 'contains', label: 'contains' },
  { value: 'notContains', label: 'does not contain' },
];

const numberComparisonOptions: Option<NumberComparison>[] = [
  { value: 'eq', label: 'is equal to' },
  { value: 'neq', label: 'is not equal' },
  { value: 'lt', label: 'is less than' },
  { value: 'gt', label: 'is greater than' },
  { value: 'lte', label: 'is less or equal to' },
  { value: 'gte', label: 'is greater or equal to' },
];

const enumComparisonOptions: Option<EnumComparison>[] = [
  { value: 'is', label: 'is' },
  { value: 'isNot', label: 'is not' },
];

function defaultComparison(
  lhs: LogicArg,
  lhsDesc: BlockOutputSchema
): LogicCondition {
  switch (lhsDesc.type) {
    case 'string':
      return {
        type: 'stringCmp',
        op: 'equal',
        args: [lhs, Logic.args.constant('')],
      };
    case 'number':
      return {
        type: 'numberCmp',
        op: 'eq',
        args: [lhs, Logic.args.constant(0)],
      };
    case 'enum':
      return {
        type: 'enumCmp',
        op: 'is',
        args: [lhs, Logic.args.constant(lhsDesc.values.at(0) ?? '')],
      };
  }
}

type ConditionEditorProps<T extends LogicCondition> = {
  value: T;
  onChange: (value: LogicCondition) => void;
  blockOutputs: BlockOutputsDesc;
  currentBlockId: string;
};

const conditionEditors: {
  [K in LogicCondition['type']]: React.ComponentType<
    ConditionEditorProps<LogicCondition>
  >;
} = {
  stringCmp: (props) => (
    <StringComparisonEditor
      {...(props as ConditionEditorProps<
        Extract<LogicCondition, { type: 'stringCmp' }>
      >)}
    />
  ),
  numberCmp: (props) => (
    <NumberComparisonEditor
      {...(props as ConditionEditorProps<
        Extract<LogicCondition, { type: 'numberCmp' }>
      >)}
    />
  ),
  enumCmp: (props) => (
    <EnumComparisonEditor
      {...(props as ConditionEditorProps<
        Extract<LogicCondition, { type: 'enumCmp' }>
      >)}
    />
  ),
  always: () => (
    <div className='w-full flex text-white text-sm'>
      <div className='w-15 font-bold pt-2'>Always</div>
    </div>
  ),
};

function ConditionEditor(props: ConditionEditorProps<LogicCondition>) {
  const EditorComponent = conditionEditors[props.value.type];
  return <EditorComponent {...props} />;
}

function useLhsChange<
  T extends Extract<
    LogicCondition,
    { type: 'stringCmp' | 'numberCmp' | 'enumCmp' }
  >
>(props: ConditionEditorProps<T>) {
  return useLiveCallback((output: BlockOutputDesc) => {
    const [lhs, rhs] = props.value.args;
    const lhsDesc =
      lhs?.type === 'output' ? props.blockOutputs[lhs.outputName] : null;

    // the new lhs may have a different schema type.
    const nextLhs = Logic.args.output(props.currentBlockId, output.name);
    if (isEqual(lhsDesc?.schema, output.schema)) {
      // if the types are the same, just replace the lhs
      props.onChange({
        ...props.value,
        args: [nextLhs, rhs],
      });
    } else {
      // types are different, so we need to change the type.
      props.onChange(defaultComparison(nextLhs, output.schema));
    }
  });
}

function StringComparisonEditor(
  props: ConditionEditorProps<Extract<LogicCondition, { type: 'stringCmp' }>>
) {
  const op = props.value.op;
  const [lhs, rhs] = props.value.args;
  const lhsDesc =
    lhs?.type === 'output' ? props.blockOutputs[lhs.outputName] : null;

  const handleLhsChange = useLhsChange(props);

  if (!lhsDesc)
    return (
      <div className='w-full h-full flex items-center justify-center text-red-002 pt-2'>
        Unknown condition
      </div>
    );

  return (
    <div className='w-full flex text-white text-sm'>
      <div className='w-15 font-bold pt-2'>If</div>
      <div className='flex-1 flex flex-col gap-2'>
        <SelectBlockOutput
          value={lhsDesc}
          onChange={handleLhsChange}
          blockOutputs={props.blockOutputs}
        />
        <div className='w-full flex items-center gap-2'>
          <Selector<StringComparison>
            value={op}
            options={stringComparisonOptions}
            onChange={(v) => {
              props.onChange({
                ...props.value,
                op: v,
              });
            }}
          />
          <input
            type='text'
            className='w-full h-9.5 m-0 field'
            placeholder='Type value...'
            value={rhs?.type === 'constant' ? rhs?.value ?? '' : ''}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                args: [lhs, Logic.args.constant(e.currentTarget.value)],
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function NumberComparisonEditor(
  props: ConditionEditorProps<Extract<LogicCondition, { type: 'numberCmp' }>>
) {
  const op = props.value.op;
  const [lhs, rhs] = props.value.args;
  const lhsDesc =
    lhs?.type === 'output' ? props.blockOutputs[lhs.outputName] : null;

  const handleLhsChange = useLhsChange(props);

  if (!lhsDesc)
    return (
      <div className='w-full h-full flex items-center justify-center text-red-002 pt-2'>
        Unknown condition
      </div>
    );

  return (
    <div className='w-full flex text-white text-sm'>
      <div className='w-15 font-bold pt-2'>If</div>
      <div className='flex-1 flex flex-col gap-2'>
        <SelectBlockOutput
          value={lhsDesc}
          onChange={handleLhsChange}
          blockOutputs={props.blockOutputs}
        />
        <div className='w-full flex items-center gap-2'>
          <Selector<NumberComparison>
            value={op}
            options={numberComparisonOptions}
            onChange={(v) => {
              props.onChange({
                ...props.value,
                op: v,
              });
            }}
          />
          <input
            type='number'
            className='w-full h-9.5 m-0 field'
            value={rhs?.type === 'constant' ? rhs?.value ?? '' : ''}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                args: [lhs, Logic.args.constant(e.currentTarget.valueAsNumber)],
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function EnumComparisonEditor(
  props: ConditionEditorProps<Extract<LogicCondition, { type: 'enumCmp' }>>
) {
  const op = props.value.op;
  const [lhs, rhs] = props.value.args;
  const lhsDesc =
    lhs?.type === 'output' ? props.blockOutputs[lhs.outputName] : null;
  const values = lhsDesc?.schema.type === 'enum' ? lhsDesc.schema.values : [];
  const handleLhsChange = useLhsChange(props);

  if (!lhsDesc)
    return (
      <div className='w-full h-full flex items-center justify-center text-red-002 pt-2'>
        Unknown condition
      </div>
    );

  return (
    <div className='w-full flex text-white text-sm'>
      <div className='w-15 font-bold pt-2'>If</div>
      <div className='flex-1 flex flex-col gap-2'>
        <SelectBlockOutput
          value={lhsDesc}
          onChange={handleLhsChange}
          blockOutputs={props.blockOutputs}
        />
        <div className='w-full flex items-center gap-2'>
          <Selector<EnumComparison>
            value={op}
            options={enumComparisonOptions}
            onChange={(v) => {
              props.onChange({
                ...props.value,
                op: v,
              });
            }}
          />
          <SelectEnumValue
            value={rhs?.type === 'constant' ? String(rhs.value) : null}
            onChange={(v) =>
              props.onChange({
                ...props.value,
                args: [lhs, Logic.args.constant(v)],
              })
            }
            enum={values}
          />
        </div>
      </div>
    </div>
  );
}

function SelectBlockOutput(props: {
  value: Nullable<BlockOutputDesc>;
  onChange: (value: BlockOutputDesc) => void;
  blockOutputs: BlockOutputsDesc;
}) {
  const styles = useInstance(() => buildReactSelectStyles<BlockOutputDesc>());

  const options = useMemo(() => {
    return Object.values(props.blockOutputs);
  }, [props.blockOutputs]);

  return (
    <Select<BlockOutputDesc, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
      value={props.value ?? options.at(0)}
      options={options}
      onChange={(v) => {
        if (v === null) return;
        props.onChange(v);
      }}
      getOptionLabel={(o) => o.displayName ?? o.name}
      getOptionValue={(o) => o.name}
      components={{
        Option: (props) => (
          <components.Option {...props}>
            <div className='flex-1 min-w-0 flex items-center gap-2'>
              <div className='text-white text-sms font-bold truncate'>
                {props.data.displayName ?? props.data.name}
              </div>
              <div className='text-icon-gray text-xs truncate'>
                {props.data.description}
              </div>
            </div>
          </components.Option>
        ),
      }}
      isSearchable={false}
      menuPosition='fixed'
    />
  );
}

function SelectEnumValue(props: {
  value: Nullable<string>;
  onChange: (value: string) => void;
  enum: string[];
}) {
  const styles = useInstance(() => buildReactSelectStyles<Option<string>>());
  const options = useMemo(() => {
    return props.enum.map((o) => ({ value: o, label: o }));
  }, [props.enum]);
  const selected = useMemo(() => {
    if (!props.value) return null;
    return (
      options.find((o) => o.value === props.value) ?? {
        value: props.value,
        label: props.value,
      }
    );
  }, [options, props.value]);

  return (
    <Select<Option<string>>
      styles={styles}
      className='flex-1 text-white'
      classNamePrefix='select-box-v2'
      value={selected}
      options={options}
      onChange={(v) => {
        if (v === null) return;
        props.onChange(v.value);
      }}
      isSearchable={false}
      menuPosition='fixed'
    />
  );
}

// actions

type ActionEditorProps<T extends LogicAction> = {
  value: T;
  onChange: (value: LogicAction) => void;
  blocks: Block[];
  minigameNames: Map<string, string>;
};

const actionEditors: {
  [K in LogicAction['type']]: React.ComponentType<
    ActionEditorProps<Extract<LogicAction, { type: K }>>
  >;
} = {
  jump: JumpActionEditor,
};

function ActionEditor(props: ActionEditorProps<LogicAction>) {
  const EditorComponent = actionEditors[props.value.type];
  return <EditorComponent {...props} />;
}

function JumpActionEditor(
  props: ActionEditorProps<Extract<LogicAction, { type: 'jump' }>>
) {
  const selected = useMemo(() => {
    return props.blocks.find((o) => o.id === props.value.props.toBlockId);
  }, [props.blocks, props.value.props.toBlockId]);

  const handleJumpChange = useLiveCallback((opt: SingleValue<Block>) => {
    if (!opt) return;
    props.onChange({
      ...props.value,
      props: { ...props.value.props, toBlockId: opt.id },
    });
  });

  return (
    <div className='w-full flex text-white text-sm'>
      <div className='w-15 font-bold pt-2'>Then</div>
      <div className='flex-1 flex item-center gap-2'>
        <SelectActionType
          value={props.value.type}
          onChange={(_type) => {
            // TODO(falcon): handle action change.
          }}
        />
        <SelectBlock
          value={selected}
          onChange={handleJumpChange}
          blocks={props.blocks}
          minigameNames={props.minigameNames}
        />
      </div>
    </div>
  );
}

// the always fallback is the last rule in the list. it's optional, but the ux provides it as a suggestion.
function isAlwaysFallback(rule: Nullable<LogicRule>): boolean {
  if (!rule) return false;
  const {
    condition,
    actions: [action],
  } = rule;
  return condition.type === 'always' && action?.type === 'jump';
}

function AlwaysFallback(props: {
  value: Nullable<LogicRule>;
  onChange: (value: Nullable<LogicRule>) => void;
  blocks: Block[];
  minigameNames: Map<string, string>;
  numRules: number;
}) {
  const [isValueFallback, value] = useMemo(() => {
    if (!props.value) return [false, null];

    const {
      condition,
      actions: [action],
    } = props.value;

    if (condition.type === 'always' && action?.type === 'jump') {
      return [true, props.blocks.find((b) => b.id === action.props.toBlockId)];
    }
    return [false, null];
  }, [props.value, props.blocks]);

  const handleChange = useLiveCallback((block: SingleValue<Block>) => {
    if (isValueFallback && props.value) {
      if (block) {
        props.onChange({
          ...props.value,
          actions: [
            {
              type: 'jump',
              props: {
                toBlockId: block.id,
              },
            },
          ],
        });
      } else {
        // we are deleting it...
        props.onChange(null);
      }
    } else if (block) {
      props.onChange({
        id: uuidv4(),
        trigger: Logic.trigger.onEnd(),
        condition: { type: 'always' },
        actions: [
          {
            type: 'jump',
            props: {
              toBlockId: block.id,
            },
          },
        ],
      });
    }
  });

  return (
    <div className='w-full px-5 py-4 bg-layer-002 rounded-md text-sm text-white flex gap-4'>
      <div className='w-max font-bold pt-2'>
        {(isValueFallback && props.numRules > 1) || props.numRules > 0 ? (
          <>All other cases go to</>
        ) : (
          <>Always go to</>
        )}
      </div>
      <div className='flex-1'>
        <SelectBlock
          value={value}
          onChange={handleChange}
          blocks={props.blocks}
          minigameNames={props.minigameNames}
          isClearable
        />
      </div>
    </div>
  );
}

function SelectBlock(props: {
  value: Nullable<Block>;
  onChange: (value: SingleValue<Block>) => void;
  blocks: Block[];
  minigameNames: Map<string, string>;
  isClearable?: boolean;
}) {
  const minigameNames = props.minigameNames;
  const styles = useInstance(() => buildReactSelectStyles<Block>());

  return (
    <Select<Block>
      styles={styles}
      className='flex-1 text-white'
      classNamePrefix='select-box-v2'
      value={props.value}
      options={props.blocks}
      onChange={props.onChange}
      isSearchable
      isClearable={props.isClearable}
      menuPosition='fixed'
      getOptionValue={(b) => b.id}
      getOptionLabel={(b) =>
        b.fields.title || BlockKnifeUtils.Summary(b).title || b.id
      }
      components={{
        Option: (props) => (
          <components.Option {...props}>
            <BlockOptionWithDetails
              block={props.data}
              label={props.label}
              minigameNames={minigameNames}
            />
          </components.Option>
        ),
        SingleValue: (props) => (
          <components.SingleValue {...props}>
            <BlockOptionWithDetails
              block={props.data}
              minigameNames={minigameNames}
            />
          </components.SingleValue>
        ),
      }}
    />
  );
}

function BlockOptionWithDetails(props: {
  block: Block;
  label?: string;
  minigameNames: Map<string, string>;
}) {
  const minigameName = props.minigameNames.get(props.block.gameId);
  const label = props.label || TrainingEditorUtils.BlockTitle(props.block);

  return (
    <div className='flex-1 min-w-0 flex items-center gap-2'>
      <SparkBlockIcon
        blockType={props.block.type}
        className='flex-none w-5 h-5'
      />
      <div className='text-white text-sms font-bold truncate'>{label}</div>
      {minigameName && (
        <div className='text-secondary text-sms truncate'>{minigameName}</div>
      )}
    </div>
  );
}

const actionOptions: Option<LogicAction['type']>[] = [
  { value: 'jump', label: 'go to' },
];

function SelectActionType(props: {
  value: LogicAction['type'];
  onChange: (value: LogicAction['type']) => void;
}) {
  return <Selector<LogicAction['type']> {...props} options={actionOptions} />;
}

function Selector<T>(props: {
  value: Nullable<T>;
  onChange: (value: T) => void;
  options: Option<T>[];
  className?: string;
}) {
  const styles = useInstance(() => buildReactSelectStyles<Option<T>>());
  return (
    <Select<Option<T>>
      styles={styles}
      className='flex-none w-42 text-white'
      classNamePrefix='select-box-v2'
      value={props.options.find((o) => o.value === props.value)}
      options={props.options}
      onChange={(v) => {
        if (v) props.onChange(v.value);
      }}
      isSearchable={false}
      menuPosition='fixed'
    />
  );
}

function getBlockOutputs(block: Block): BlockOutputsDesc {
  switch (block.type) {
    case BlockType.MULTIPLE_CHOICE:
      return getMultipleChoiceOutputSchema(block);
    case BlockType.QUESTION:
      return getQuestionOutputSchema(block);
    case BlockType.MEMORY_MATCH:
      return getMatchOutputSchema(block);
    case BlockType.ROLEPLAY:
      return getRoleplayOutputSchema(block);
    case BlockType.HIDDEN_PICTURE:
      return getHiddenPictureOutputSchema(block) as BlockOutputsDesc;
    case BlockType.SCENARIO:
      return getScenarioOutputSchema(block);
    default:
      return {};
  }
}
