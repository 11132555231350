import {
  type BlockSchema,
  checkBlockIsFileBlock,
  type DefaultBlockSchema,
  type DefaultInlineContentSchema,
  type DefaultStyleSchema,
  type InlineContentSchema,
  type StyleSchema,
} from '@blocknote/core';
import {
  type ComponentProps,
  type FilePanelProps,
  FormattingToolbar,
  type FormattingToolbarProps,
  getFormattingToolbarItems,
  UploadTab,
  useBlockNoteEditor,
  useComponentsContext,
  useDictionary,
  useSelectedBlocks,
} from '@blocknote/react';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { ImageIcon } from '../../icons/ImageIcon';
import { MediaSearchModal } from '../../MediaSearch/MediaSearch';

export function CustomFormattingToolbar(props: FormattingToolbarProps) {
  const items = useMemo(() => {
    // we need to replace the file replace button with our custom one
    const items = getFormattingToolbarItems(props.blockTypeSelectItems);
    for (let i = 0; i < items.length; i++) {
      if (items[i].key === 'replaceFileButton') {
        items.splice(i, 1, <FileReplaceButton key={'replaceFileButton'} />);
        break;
      }
    }
    return items;
  }, [props.blockTypeSelectItems]);
  return <FormattingToolbar {...props}>{items}</FormattingToolbar>;
}

export function CustomFilePanel(
  props: FilePanelProps & {
    onClose?: () => void;
  }
) {
  const editor = useBlockNoteEditor();
  const handleClose = useLiveCallback(() => {
    if (props.onClose) {
      props.onClose();
    } else {
      editor.filePanel?.closeMenu();
    }
  });

  // For image blocks, show our media search modal
  if (props.block.type === 'image') {
    return createPortal(
      <div className='fixed inset-0 flex items-center justify-center'>
        <div className='fixed inset-0 bg-lp-black-002' onClick={handleClose} />
        <ModalWrapper
          onClose={handleClose}
          containerClassName='w-[900px] h-[600px]'
          borderStyle='gray'
        >
          <MediaSearchModal
            video={false}
            onUploadSuccess={(media) => {
              editor.updateBlock(props.block, {
                type: 'image',
                props: {
                  url: media.url,
                },
              });
            }}
          />
        </ModalWrapper>
      </div>,
      document.body
    );
  }

  // For other media types (video, audio, file), show upload only tab
  return <FilePanel {...props} />;
}

type PanelProps = ComponentProps['FilePanel']['Root'];

/**
 * note: this is a clone of the FilePanel component from @blocknote/react, but with
 * embed removed. while the version provided by their library is customizable, it's
 * not really friendly because of the nested loading state.
 */
const FilePanel = <
  B extends BlockSchema = DefaultBlockSchema,
  I extends InlineContentSchema = DefaultInlineContentSchema,
  S extends StyleSchema = DefaultStyleSchema
>(
  props: FilePanelProps<I, S> &
    Partial<Pick<PanelProps, 'defaultOpenTab' | 'tabs'>>
) => {
  const Components = useComponentsContext();
  const dict = useDictionary();

  const editor = useBlockNoteEditor<B, I, S>();

  const [loading, setLoading] = useState<boolean>(false);

  const tabs: PanelProps['tabs'] = props.tabs ?? [
    ...(editor.uploadFile !== undefined
      ? [
          {
            name: dict.file_panel.upload.title,
            tabPanel: <UploadTab block={props.block} setLoading={setLoading} />,
          },
        ]
      : []),
  ];

  const [openTab, setOpenTab] = useState<string>(
    props.defaultOpenTab || tabs[0].name
  );

  if (!Components) {
    return null;
  }

  return (
    <Components.FilePanel.Root
      className={'bn-panel'}
      defaultOpenTab={openTab}
      openTab={openTab}
      setOpenTab={setOpenTab}
      tabs={tabs}
      loading={loading}
    />
  );
};

/**
 * note: this is a clone of the FileReplaceButton component from @blocknote/react,
 * but with our custom file upload widget. again, the library version of this
 * component is not very customizable.
 */
const FileReplaceButton = () => {
  const dict = useDictionary();
  const Components = useComponentsContext();

  const editor = useBlockNoteEditor<
    BlockSchema,
    InlineContentSchema,
    StyleSchema
  >();

  const selectedBlocks = useSelectedBlocks(editor);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedBlocks]);

  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;

  if (
    !Components ||
    block === undefined ||
    !checkBlockIsFileBlock(block, editor) ||
    !editor.isEditable
  ) {
    return null;
  }

  return (
    <Components.Generic.Popover.Root opened={isOpen} position={'bottom'}>
      <Components.Generic.Popover.Trigger>
        <Components.FormattingToolbar.Button
          className={'bn-button'}
          onClick={() => setIsOpen(!isOpen)}
          isSelected={isOpen}
          mainTooltip={
            dict.formatting_toolbar.file_replace.tooltip[block.type] ||
            dict.formatting_toolbar.file_replace.tooltip['file']
          }
          label={
            dict.formatting_toolbar.file_replace.tooltip[block.type] ||
            dict.formatting_toolbar.file_replace.tooltip['file']
          }
          icon={<ImageIcon />}
        />
      </Components.Generic.Popover.Trigger>
      <Components.Generic.Popover.Content
        className={'bn-popover-content bn-panel-popover'}
        variant={'panel-popover'}
      >
        {/* Replaces default file panel with ours */}
        <CustomFilePanel
          block={block as any}
          onClose={() => setIsOpen(false)}
        />
      </Components.Generic.Popover.Content>
    </Components.Generic.Popover.Root>
  );
};
