import capitalize from 'lodash/capitalize';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';
import { useSnapshot } from 'valtio';

import { EnumsJeopardyClueType } from '@lp-lib/api-service-client/public';
import { type JeopardyBlock, type JeopardyClue } from '@lp-lib/game';

import { buildReactSelectStyles } from '../../../utils/react-select';
import type { Option } from '../../common/Utilities';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { JeopardyEditorBoard } from '../../GameV2/blocks/Jeopardy/JeopardyBoard';
import { JeopardyEditorPlayerTracker } from '../../GameV2/blocks/Jeopardy/JeopardyPlayerTracker';
import { JeopardyEditorTurnTracker } from '../../GameV2/blocks/Jeopardy/JeopardyTurnTracker';
import { JeopardyBackground } from '../../GameV2/blocks/Jeopardy/Shared';
import { JeopardyUtils } from '../../GameV2/blocks/Jeopardy/utils';
import { ArrowLeftIcon } from '../../icons/Arrows';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import {
  CorrectAnswerSelect,
  MultipleChoiceEditor,
} from './MultipleChoiceBlockEditor';
import { QuestionEditor } from './QuestionBlockEditor';
import { type TrainingSlideEditorProps } from './types';

type View = 'clues' | 'answers' | 'points';

type State = {
  view: View;
  activeClueId?: string | null;
};

function initState(): State {
  return {
    view: 'clues',
  };
}

const state = proxy<State>(initState());

export function JeopardyBlockEditorTabs() {
  const { view } = useSnapshot(state);

  useEffect(() => {
    return () => {
      state.view = 'clues';
      state.activeClueId = undefined;
    };
  }, []);

  return (
    <div className='p-2 flex items-center gap-4 text-white'>
      <span className='text-sms font-bold text-white'>View:</span>
      {['clues', 'answers', 'points'].map((tab) => (
        <button
          key={tab}
          type='button'
          className={`
                      btn-secondary rounded-lg w-25 h-8 text-base transition-colors relative
                      ${
                        view === tab
                          ? 'text-white border border-white'
                          : 'text-icon-gray hover:text-white'
                      }
                    `}
          onClick={() => (state.view = tab as View)}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

function JeopardyClueEditor(props: {
  clue: JeopardyClue;
  onClueChange: (
    updates: Partial<JeopardyClue>,
    updateRemote?: boolean
  ) => void;
  onBack: () => void;
}) {
  const { clue, onClueChange, onBack } = props;

  return (
    <div className='relative w-full h-full'>
      {match(clue.type)
        .with(EnumsJeopardyClueType.JeopardyClueTypeQuestion, () => (
          <QuestionEditor
            question={clue.question}
            answer={clue.answer}
            onQuestionChange={(value) => {
              onClueChange({ question: value });
            }}
            onAnswerChange={(value) => {
              onClueChange({ answer: value });
            }}
            onAnswerBlur={(value) => {
              onClueChange({ answer: value });
            }}
          />
        ))
        .otherwise(() => (
          <MultipleChoiceEditor
            question={clue.question}
            answerChoices={clue.answerChoices || []}
            maxChoicesCount={3}
            onQuestionChange={(value) => {
              onClueChange({ question: value });
            }}
            onAnswerChoicesChange={(value) => {
              onClueChange({ answerChoices: value });
            }}
          />
        ))}

      <div className='absolute top-5 left-5'>
        <button
          type='button'
          className='btn text-white flex items-center gap-2'
          onClick={onBack}
        >
          <ArrowLeftIcon className='w-5 h-5 fill-current' />
          Back
        </button>
      </div>
    </div>
  );
}

function ClueTypeSelect(props: {
  value?: EnumsJeopardyClueType | null;
  onChange: (value: EnumsJeopardyClueType) => void;
}) {
  const options = useMemo(() => {
    return [
      {
        label: 'Multiple Choice',
        value: EnumsJeopardyClueType.JeopardyClueTypeMultipleChoice,
      },
      {
        label: 'Question',
        value: EnumsJeopardyClueType.JeopardyClueTypeQuestion,
      },
    ];
  }, []);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    if (!props.value) return options[0];

    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(
    () => buildReactSelectStyles<Option<EnumsJeopardyClueType>>(),
    []
  );

  return (
    <Select<Option<EnumsJeopardyClueType>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      onChange={(v) => {
        if (!v) return;
        props.onChange(v.value);
      }}
    />
  );
}

function JeopardyClueSidebar(props: {
  clue: JeopardyClue;
  onClueChange: (updates: Partial<JeopardyClue>) => void;
}) {
  const { clue, onClueChange } = props;

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <div className='flex flex-col gap-1'>
        <p className='text-base font-bold'>Question Type</p>
        <ClueTypeSelect
          value={clue.type}
          onChange={(value) => {
            onClueChange({ type: value });
          }}
        />
      </div>

      <div className='flex flex-col gap-1'>
        <p className='text-base font-bold'>Points</p>
        <PointsInput
          defaultValue={clue.value}
          max={10000}
          min={1}
          placeholder={'Max 10000 points'}
          onChange={(value) => {
            onClueChange({ value });
          }}
        />
      </div>

      {(!clue.type ||
        clue.type === EnumsJeopardyClueType.JeopardyClueTypeMultipleChoice) && (
        <div className='flex flex-col gap-1'>
          <p className='text-base text-white font-bold'>Correct Answer</p>
          <CorrectAnswerSelect
            choices={clue.answerChoices || []}
            onChange={(value) => {
              onClueChange({ answerChoices: value });
            }}
          />
        </div>
      )}
    </div>
  );
}

export function JeopardyBlockEditor(
  props: TrainingSlideEditorProps<JeopardyBlock>
) {
  const { view, activeClueId } = useSnapshot(state);
  const { getField, onChange, onBlur } = useTrainingSlideEditor(props);
  const activeClue = JeopardyUtils.FindClue(
    props.block.fields.board,
    activeClueId
  );

  return (
    <>
      {!activeClue && (
        <div className='absolute inset-0'>
          <JeopardyBackground />
        </div>
      )}

      <div className='relative w-full h-full'>
        <div className='relative w-full h-full py-3 flex flex-col items-center'>
          <JeopardyEditorPlayerTracker />

          <div className='mt-7 w-full flex justify-center items-center'>
            <JeopardyEditorTurnTracker />
          </div>

          <div
            className={`
              mt-2 w-full flex-1 overflow-hidden bg-black p-2.5
            `}
          >
            <div className='relative w-full h-full'>
              <JeopardyEditorBoard
                block={props.block}
                onCategoryChange={(category) => {
                  const newBoard = JeopardyUtils.UpdateBoardCategory(
                    props.block.fields.board,
                    category
                  );
                  onChange('board', newBoard);
                  onBlur('board', newBoard);
                }}
                onEditClue={(clue) => {
                  state.activeClueId = clue.id;
                }}
                view={view}
              />
            </div>
          </div>
        </div>

        {activeClue && (
          <div className='absolute inset-0 bg-black'>
            <JeopardyClueEditor
              clue={activeClue as JeopardyClue}
              onClueChange={(updates, updateRemote = true) => {
                const currentBoard = getField('board');
                const currentClue = JeopardyUtils.FindClue(
                  currentBoard,
                  activeClue.id
                );
                if (!currentClue) return;
                const newClue = { ...currentClue, ...updates };
                const newBoard = JeopardyUtils.UpdateBoardClue(
                  currentBoard,
                  newClue
                );
                onChange('board', newBoard);
                if (updateRemote) {
                  onBlur('board', newBoard);
                }
              }}
              onBack={() => {
                state.activeClueId = null;
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export function JeopardyBlockEditorSidebar(
  props: TrainingSlideEditorProps<JeopardyBlock>
) {
  const { block } = props;
  const { onChange, onBlur, getField } = useTrainingSlideEditor(props);

  const { activeClueId } = useSnapshot(state);
  const activeClue = JeopardyUtils.FindClue(block.fields.board, activeClueId);

  if (activeClue) {
    return (
      <JeopardyClueSidebar
        clue={activeClue as JeopardyClue}
        onClueChange={(updates) => {
          const currentBoard = getField('board');
          const currentClue = JeopardyUtils.FindClue(
            currentBoard,
            activeClue.id
          );
          if (!currentClue) return;
          const newClue = { ...currentClue, ...updates };
          console.log('currentClue', currentClue);
          const newBoard = JeopardyUtils.UpdateBoardClue(currentBoard, newClue);
          onChange('board', newBoard);
          onBlur('board', newBoard);
        }}
      />
    );
  }

  return (
    <div className='relative w-full h-full flex flex-col gap-5'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Board Name</p>
        <input
          type='text'
          className='field w-full mb-0'
          value={block.fields.board?.name ?? ''}
          onChange={(e) => {
            const currentBoard = getField('board');
            const newBoard = { ...currentBoard, name: e.target.value };
            onChange('board', newBoard);
          }}
          onBlur={(e) => {
            const currentBoard = getField('board');
            const newBoard = { ...currentBoard, name: e.target.value };
            onBlur('board', newBoard);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Board Size & Rules
        </p>
        <p className='text-3xs text-icon-gray'>
          Board has 9 questions in total, but player only plays 5 clues from the
          board.
        </p>
      </label>
    </div>
  );
}
