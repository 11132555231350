import Select from 'react-select';

import {
  EnumsSparkBackgroundOption,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import type { Block } from '@lp-lib/game';
import type { Media } from '@lp-lib/media';

import { fromMediaDTO, toMediaDTO } from '../../../../utils/api-dto';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { ImageIcon } from '../../../icons/ImageIcon';
import { useTriggerMediaSearchModal } from '../../../MediaSearch/useTriggerMediaSearchModal';
import { useTrainingSlideEditor } from '../hooks';
import type { TrainingSlideEditorProps } from '../types';

export interface SparkBackgroundSelectProps {
  value?: EnumsSparkBackgroundOption | null;
  onChange: (value: EnumsSparkBackgroundOption | null) => void;
}

export function SparkBackgroundSelect({
  value,
  onChange,
}: SparkBackgroundSelectProps) {
  const options = [
    {
      label: 'None',
      value: EnumsSparkBackgroundOption.SparkBackgroundOptionNone,
    },
    {
      label: 'Brand Color',
      value: EnumsSparkBackgroundOption.SparkBackgroundOptionBrandColor,
    },
    {
      label: 'Video',
      value: EnumsSparkBackgroundOption.SparkBackgroundOptionBlockVideo,
    },
    {
      label: 'Media',
      value: EnumsSparkBackgroundOption.SparkBackgroundOptionMedia,
    },
    {
      label: 'Media with Overlay',
      value: EnumsSparkBackgroundOption.SparkBackgroundOptionMediaWithOverlay,
    },
  ];

  return (
    <Select
      options={options}
      value={options.find((o) => o.value === value)}
      onChange={(o) => o && onChange(o.value)}
      styles={buildReactSelectStyles()}
      classNamePrefix='select-box-v2'
    />
  );
}

export function SparkBackgroundMediaField<
  T extends Block & {
    fields: {
      sparkBackgroundOption?: EnumsSparkBackgroundOption | null;
      sparkBackgroundMedia?: ModelsMediaAsset | null;
    };
  }
>(props: TrainingSlideEditorProps<T>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const value = block.fields.sparkBackgroundMedia;

  const triggerMediaSearchModal = useTriggerMediaSearchModal();

  const handleUploadSuccess = (media: Media) => {
    const asset: ModelsMediaAsset = {
      media: toMediaDTO(media),
      data: {
        id: media.id,
      },
    };
    onChange('sparkBackgroundMedia', asset);
    onBlur('sparkBackgroundMedia', asset);
  };

  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      onUploadSuccess: handleUploadSuccess,
      video: true,
    });
  };

  const deleteMedia = () => {
    onChange('sparkBackgroundMedia', null);
    onBlur('sparkBackgroundMedia', null);
  };

  const displayUrl = MediaUtils.PickMediaUrl(fromMediaDTO(value?.media), {
    priority: ImagePickPriorityHighToLow,
    videoThumbnail: 'first',
  });

  const isMediaOption =
    block.fields.sparkBackgroundOption ===
      EnumsSparkBackgroundOption.SparkBackgroundOptionMedia ||
    block.fields.sparkBackgroundOption ===
      EnumsSparkBackgroundOption.SparkBackgroundOptionMediaWithOverlay;

  if (!isMediaOption) {
    return null;
  }

  return (
    <div className='flex gap-1 items-center'>
      <button
        type='button'
        className='flex gap-1 items-center text-icon-gray text-sms'
        onClick={openMediaSearchModal}
      >
        {displayUrl ? (
          <img
            src={displayUrl}
            alt=''
            className='w-6.5 h-5 border border-icon-gray rounded-sm'
          />
        ) : (
          <span className='px-1.5 py-0.75 border border-icon-gray rounded-sm'>
            <ImageIcon className='w-3 h-3' />
          </span>
        )}
        Background Media
      </button>
      {displayUrl ? (
        <button type='button' onClick={deleteMedia}>
          <DeleteIcon className='w-2.5 h-2.5 text-red-006 fill-current' />
        </button>
      ) : null}
    </div>
  );
}
