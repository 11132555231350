import cloneDeep from 'lodash/cloneDeep';
import pluralize from 'pluralize';
import { Fragment, useEffect, useLayoutEffect } from 'react';
import Select from 'react-select';
import { proxy, useSnapshot } from 'valtio';

import {
  type ModelsMediaAsset,
  type ModelsSwipeToWinCardPair,
} from '@lp-lib/api-service-client/public';
import { type SwipeToWinBlock } from '@lp-lib/game';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { MediaUtils } from '../../../utils/media';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { CommonInput } from '../../GameV2/design/Input';
import { SparkBlockBackground } from '../../GameV2/design/SparkBackground';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons/Arrows';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import {
  SparkBackgroundMediaField,
  SparkBackgroundSelect,
} from './Shared/SparkBackgroundSelect';
import { type TrainingSlideEditorProps } from './types';

type State = {
  editingCardPairIndex: number;
  cardType: 'media' | 'text';
};

const state = proxy<State>({
  editingCardPairIndex: 0,
  cardType: 'media',
});

export function SwipeToWinBlockEditor(
  props: TrainingSlideEditorProps<SwipeToWinBlock>
) {
  return <Left {...props} />;
}

export function SwipeToWinBlockSidebarEditor(
  props: TrainingSlideEditorProps<SwipeToWinBlock>
) {
  return <Right {...props} />;
}

export function SwipeToWinBlockEditorUnder(
  props: TrainingSlideEditorProps<SwipeToWinBlock>
) {
  return (
    <div className='flex flex-col gap-5 text-white'>
      <SparkBackgroundMediaField {...props} />
    </div>
  );
}

function MediaField(props: {
  asset: ModelsMediaAsset | null;
  onChange: (asset: ModelsMediaAsset | null) => void;
}) {
  const media = fromMediaDTO(props.asset?.media);
  const url = MediaUtils.PickMediaUrl(media);
  const triggerMediaSearchModal = useTriggerMediaSearchModal();
  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      onUploadSuccess: (media) => {
        const asset: ModelsMediaAsset = {
          media: toMediaDTO(media),
          data: {
            id: media.id,
          },
        };
        props.onChange(asset);
      },
    });
  };

  if (url) {
    return (
      <div
        className='w-full h-full min-h-0 relative rounded-xl group cursor-pointer flex justify-center'
        onClick={openMediaSearchModal}
        style={{ aspectRatio: '16/9' }}
      >
        <img
          src={url}
          alt='card media'
          className='w-full h-full object-cover rounded-xl'
        />
        <div className='absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity'>
          <button
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              props.onChange(null);
            }}
            className='absolute top-2 right-2 text-white hover:text-red-500 hover:border-red-500 border-2 p-2 border-white rounded-full fill-current'
          >
            <DeleteIcon className='w-5 h-5' />
          </button>
          <div className='w-full h-full flex items-center justify-center'>
            <p className='text-white text-center'>Click to replace the image</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <button
      type='button'
      className='w-full h-full bg-main-layer border border-secondary rounded-xl flex flex-col items-center justify-center gap-4 hover:bg-light-gray transition-colors'
      onClick={openMediaSearchModal}
    >
      <ImageIcon className='w-12 h-12 text-white' />
      <div className='text-center'>
        <div className='text-white'>Add Media</div>
      </div>
    </button>
  );
}

function CardPairEditor(props: {
  cardPair: ModelsSwipeToWinCardPair;
  cardType: 'media' | 'text';
  index: number;
  onUpdate: (
    idx: number,
    value: ModelsSwipeToWinCardPair,
    localOnly: boolean
  ) => void;
  accessory?: React.ReactNode;
}) {
  const { cardPair, cardType } = props;

  const onUpdate = useLiveCallback(
    (value: Partial<ModelsSwipeToWinCardPair>, localOnly: boolean) => {
      props.onUpdate(
        props.index,
        {
          ...cardPair,
          ...value,
        },
        localOnly
      );
    }
  );

  return (
    <div className='min-h-0 h-full max-h-full flex flex-col gap-2 2xl:gap-10'>
      <div
        className='min-h-0 max-h-full w-full flex flex-col gap-2'
        style={{ flexGrow: 2 }}
      >
        <div
          className={`min-h-0 h-full lp-md:h-auto lp-md:w-full max-h-full self-center border border-secondary rounded-xl relative
            text-white flex items-center justify-center text-center ${
              cardType === 'media' ? 'bg-black' : 'bg-lp-blue-002'
            }`}
          style={{ aspectRatio: '16/9' }}
        >
          <p className='text-3xs absolute left-1.5 top-1.5'>
            #{props.index + 1}
          </p>
          {cardType === 'media' ? (
            <MediaField
              asset={cardPair.firstMedia ?? null}
              onChange={(asset) => {
                onUpdate(
                  {
                    firstMedia: asset,
                  },
                  false
                );
              }}
            />
          ) : (
            <FFriendlyEditableText
              value={cardPair.firstText}
              onBlur={(val) => {
                onUpdate(
                  {
                    firstText: val,
                  },
                  false
                );
              }}
              className={`
                      w-full outline-none cursor-text
                      contenteditable-placeholder whitespace-pre-wrap break-words
                      text-base sm:text-xl md:text-2xl lg:text-3xl
                    `}
              placeholder='Enter text here'
              style={{
                '--placeholder-color': 'rgba(255, 255, 255, 0.7)',
              }}
            />
          )}
          {props.accessory}
        </div>
      </div>

      <CommonInput
        type='text'
        placeholder='Text that matches media above'
        value={cardPair.secondText}
        onChange={(e) =>
          onUpdate(
            {
              secondText: e.currentTarget.value,
            },
            true
          )
        }
        onBlur={(e) =>
          onUpdate(
            {
              secondText: e.currentTarget.value,
            },
            false
          )
        }
        variant='brand'
      />
    </div>
  );
}

function ArrowButton(props: {
  direction: 'left' | 'right';
  onClick?: () => void;
  visible?: boolean;
  disabled?: boolean;
}) {
  if (!props.visible) return null;

  return (
    <button
      type='button'
      className={`btn w-12 h-12 bg-black bg-opacity-80 border border-secondary 
    rounded-full flex items-center justify-center cursor-pointer absolute 
    top-1/2 transform -translate-y-1/2 ${
      props.direction === 'left'
        ? 'left-0 -translate-x-1/3'
        : 'right-0 translate-x-1/3'
    }`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.direction === 'left' ? (
        <ArrowLeftIcon className='w-5 h-5 fill-current' />
      ) : (
        <ArrowRightIcon className='w-5 h-5 fill-current' />
      )}
    </button>
  );
}

function Left(props: TrainingSlideEditorProps<SwipeToWinBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const cardPairs = block.fields.cardPairs ?? [];
  const { editingCardPairIndex, cardType } = useSnapshot(state);

  const setEditingCardPairIndex = useLiveCallback((idx: number) => {
    state.editingCardPairIndex = idx;
  });

  const handleAddCardPair = useLiveCallback(() => {
    const pairs = block.fields.cardPairs ?? [];

    const next = [...pairs, { firstText: '', secondText: '' }];
    onChange('cardPairs', next);
    onBlur('cardPairs', next);
  });

  const handleRemoveCardPair = useLiveCallback((idx: number) => {
    const next = [...(block.fields.cardPairs ?? [])];
    next.splice(idx, 1);
    onChange('cardPairs', next);
    onBlur('cardPairs', next);
  });

  const handleUpdateCardPair = useLiveCallback(
    (idx: number, value: ModelsSwipeToWinCardPair, localOnly: boolean) => {
      const next = [...(block.fields.cardPairs ?? [])];
      next[idx] = value;
      if (localOnly) {
        onChange('cardPairs', next);
      } else {
        onChange('cardPairs', next);
        onBlur('cardPairs', next);
      }
    }
  );

  const syncCardType = useLiveCallback(() => {
    const cardPair = cardPairs[editingCardPairIndex];
    if (!cardPair) return;
    state.cardType =
      !!cardPair.firstMedia || cardPair.firstText.length === 0
        ? 'media'
        : 'text';
  });

  useLayoutEffect(() => {
    syncCardType();
  }, [editingCardPairIndex, syncCardType]);

  useEffect(() => {
    return () => {
      state.editingCardPairIndex = 0;
      state.cardType = 'media';
    };
  }, []);

  const cardPair = cardPairs[editingCardPairIndex];

  return (
    <>
      <SparkBlockBackground block={props.block} position='absolute' noVideo />
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main className='w-full flex-1 min-h-0 px-5 flex flex-col justify-start my-10 gap-5 2xl:gap-10'>
          <div className='text-white text-base text-center px-5'>
            <FFriendlyEditableText
              value={block.fields.instruction || ''}
              onBlur={(val) => {
                onChange('instruction', val);
                onBlur('instruction', val);
              }}
              className={`
                        w-full outline-none cursor-text
                        contenteditable-placeholder whitespace-pre-wrap break-words
                        text-base sm:text-xl md:text-2xl lg:text-3xl
                      `}
              placeholder='Type your instructions here'
            />
          </div>
          {cardPair ? (
            <div
              className='w-full sm:w-120 min-h-0 relative self-center'
              style={{ flexGrow: 1.5 }}
            >
              <CardPairEditor
                index={editingCardPairIndex}
                cardPair={cardPair}
                cardType={cardType}
                onUpdate={handleUpdateCardPair}
                accessory={
                  <Fragment>
                    <ArrowButton
                      direction='left'
                      visible={cardPairs.length > 1}
                      disabled={editingCardPairIndex === 0}
                      onClick={() =>
                        setEditingCardPairIndex(editingCardPairIndex - 1)
                      }
                    />
                    <ArrowButton
                      direction='right'
                      visible={cardPairs.length > 1}
                      disabled={editingCardPairIndex === cardPairs.length - 1}
                      onClick={() =>
                        setEditingCardPairIndex(editingCardPairIndex + 1)
                      }
                    />
                  </Fragment>
                }
              />
            </div>
          ) : null}
          <div className='flex flex-col items-center text-sms mt-10'>
            <p className='text-icon-gray'>
              {cardPairs.length} {pluralize('item', cardPairs.length)} to match
            </p>
            <div className='flex items-center justify-center gap-2'>
              <button
                type='button'
                className='btn text-primary'
                onClick={() => {
                  handleAddCardPair();
                  setEditingCardPairIndex(cardPairs.length);
                }}
              >
                Add Item
              </button>
              {cardPair ? (
                <button
                  type='button'
                  className='btn text-red-003'
                  onClick={() => {
                    handleRemoveCardPair(editingCardPairIndex);
                    if (editingCardPairIndex > 0) {
                      setEditingCardPairIndex(editingCardPairIndex - 1);
                    } else {
                      setEditingCardPairIndex(0);
                    }
                  }}
                  disabled={cardPairs.length === 1}
                >
                  Delete Item
                </button>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

type CardTypeOption = {
  value: 'media' | 'text';
  label: string;
};

const cardTypeOptions: CardTypeOption[] = [
  {
    value: 'media',
    label: 'Media',
  },
  {
    value: 'text',
    label: 'Text',
  },
];

export function CardTypeSelect(props: {
  value: CardTypeOption['value'];
  onChange: (value: CardTypeOption) => void;
  disabled?: boolean;
}) {
  const styles = useInstance(() => buildReactSelectStyles());
  const selected =
    cardTypeOptions.find((o) => o.value === props.value) ?? cardTypeOptions[0];

  return (
    <Select<CardTypeOption, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
      value={selected}
      options={cardTypeOptions}
      onChange={(v) => {
        if (v === null) return;
        props.onChange(v);
      }}
      isSearchable={false}
      menuPlacement='auto'
      menuPosition='fixed'
      isDisabled={props.disabled}
    />
  );
}

function Right(props: TrainingSlideEditorProps<SwipeToWinBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const cardPairs = block.fields.cardPairs ?? [];
  const { editingCardPairIndex, cardType } = useSnapshot(state);
  const cardPair = cardPairs[editingCardPairIndex];

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Card Type</p>
        <CardTypeSelect
          value={cardType}
          onChange={(value) => {
            state.cardType = value.value;
          }}
          disabled={!cardPair}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Points Earned Per Match
        </p>
        <PointsInput
          defaultValue={block.fields.pointsPerMatch}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('pointsPerMatch', value);
            onBlur('pointsPerMatch', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Background</p>
        <SparkBackgroundSelect
          value={block.fields.sparkBackgroundOption}
          onChange={(value) => {
            onChange('sparkBackgroundOption', value);
            onBlur('sparkBackgroundOption', value);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
    </div>
  );
}
