import { useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import { z } from 'zod';

import {
  type ModelsScenarioRubric,
  type ModelsScenarioRubricItem,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { uuidv4 } from '../../../utils/common';
import { DragDropList } from '../../common/DragDrop';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
} from '../../ConfirmCancelModalContext';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ScoreRing } from '../../GameV2/design/ScoreRing';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { Loading } from '../../Loading';

function RubricItem(props: {
  item: ModelsScenarioRubricItem;
  drag: ConnectDragSource;
  onDelete: () => void;
  onChange: (item: ModelsScenarioRubricItem) => void;
}) {
  const { item, drag, onDelete, onChange } = props;

  const [editingTitle, setEditingTitle] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className='w-full flex gap-2.5'>
      <div ref={drag} className='mt-2 flex-none btn cursor-move'>
        <MenuIcon className='w-3.5 h-3.5 md:w-4 md:h-4 fill-current' />
      </div>

      <div className='flex-1 overflow-hidden bg-dark-gray border border-secondary rounded p-2 flex flex-col gap-1.5'>
        <div className='h-4 md:h-5 flex items-center justify-between gap-2'>
          <div className='flex-1 h-full truncate text-2xs md:text-sm flex items-center'>
            {editingTitle ? (
              <input
                type='text'
                className='field w-full h-full mb-0 px-1 rounded'
                // Remount if new props come in to force an updated defaultValue
                key={item.title}
                defaultValue={item.title}
                autoFocus
                onBlur={(e) => {
                  setEditingTitle(false);
                  onChange({ ...item, title: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
              />
            ) : (
              <span
                className={`w-full h-full truncate text-2xs md:text-sm ${
                  !item.title ? 'text-icon-gray' : ''
                }`}
                onClick={() => setEditingTitle(true)}
              >
                {item.title || 'Click to enter a title'}
              </span>
            )}
          </div>

          <div className='flex-none flex items-center gap-2.5 text-icon-gray'>
            {!editingTitle && (
              <button type='button' onClick={() => setEditingTitle(true)}>
                <EditIcon className='w-3 h-3 md:w-4 md:h-4 fill-current' />
              </button>
            )}
            <button
              type='button'
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {collapsed ? (
                <ArrowDownIcon className='w-3 h-3 md:w-4 md:h-4 fill-current' />
              ) : (
                <ArrowUpIcon className='w-3 h-3 md:w-4 md:h-4 fill-current' />
              )}
            </button>
            <button type='button' onClick={onDelete}>
              <DeleteIcon className='w-3 h-3 md:w-4 md:h-4 fill-current' />
            </button>
          </div>
        </div>

        <textarea
          className={`h-14 field rounded m-0 p-1 text-3xs md:text-2xs ${
            collapsed ? 'hidden' : ''
          }`}
          defaultValue={item.description}
          placeholder='Enter a description for this item'
          onBlur={(e) => {
            onChange({ ...item, description: e.target.value });
          }}
        />
      </div>
    </div>
  );
}

function RubricSection(props: {
  title: string;
  type: string;
  items: ModelsScenarioRubricItem[];
  onChange: (items: ModelsScenarioRubricItem[]) => void;
}) {
  const { title, type, items, onChange } = props;

  const handleAdd = () => {
    onChange([
      {
        id: uuidv4(),
        title: '',
        description: '',
      },
      ...items,
    ]);
  };

  const handleDelete = (id: string) => {
    onChange(items.filter((item) => item.id !== id));
  };

  const handleChange = (id: string, item: ModelsScenarioRubricItem) => {
    onChange(items.map((i) => (i.id === id ? item : i)));
  };

  const handleMove = (from: number, to: number) => {
    const newItems = [...items];
    const [removed] = newItems.splice(from, 1);
    newItems.splice(to, 0, removed);
    onChange(newItems);
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <p className='text-base md:text-lg font-bold'>{title}</p>
        <button
          type='button'
          className='btn text-icon-gray text-2xs md:text-sm font-medium'
          onClick={handleAdd}
        >
          + Add Item
        </button>
      </div>

      <DragDropList
        type={type}
        items={items}
        onMove={handleMove}
        render={({ item, drag, ref, style }) => (
          <div className='w-full' ref={ref} style={style}>
            <RubricItem
              item={item}
              drag={drag}
              onDelete={() => handleDelete(item.id)}
              onChange={(updatedItem) => handleChange(item.id, updatedItem)}
            />
          </div>
        )}
      />
    </div>
  );
}

function RubricItemPlaceholder() {
  return (
    <div className='w-full h-8 border border-secondary bg-dark-gray rounded'></div>
  );
}

function RubricSectionPlaceholder(props: { title: string }) {
  const { title } = props;

  return (
    <section className='flex flex-col gap-2'>
      <p className='text-sms font-bold'>{title}</p>
      <div className='flex flex-col gap-2'>
        <RubricItemPlaceholder />
        <RubricItemPlaceholder />
        <RubricItemPlaceholder />
      </div>
    </section>
  );
}

function ZeroState() {
  return (
    <div className='w-full flex flex-col items-center gap-4'>
      <p className='text-xl font-bold'>Evaluation Rubric</p>
      <div className='flex flex-col gap-2 items-center'>
        <ScoreRing score={null} total={null} />
        <p className='max-w-90 text-center text-sms italic text-icon-gray'>
          Generate an evaluation by entering a question and gold answer, then
          click "Generate Evaluation"
        </p>
      </div>
      <div className='w-full max-w-115 flex flex-col gap-4'>
        <RubricSectionPlaceholder title='Things Said Correctly' />
        <RubricSectionPlaceholder title='Things Avoided Saying' />
      </div>
    </div>
  );
}

export function ScenarioBlockEditorRubric(props: {
  value: ModelsScenarioRubric | null;
  onChange: (value: ModelsScenarioRubric) => void;
}) {
  const { value, onChange } = props;

  if (!value) return <ZeroState />;
  return (
    <div className='w-full max-w-2xl flex flex-col items-center gap-4'>
      <p className='text-xl md:text-2xl font-bold'>Evaluation Rubric</p>
      <ScoreRing score={73} total={100} />
      <div className='w-full flex flex-col gap-8'>
        <RubricSection
          title='Things Said Correctly'
          type='thingsSaidCorrectly'
          items={value?.thingsSaidCorrectly ?? []}
          onChange={(items) =>
            onChange({ ...value, thingsSaidCorrectly: items })
          }
        />

        <RubricSection
          title='Things Avoided Saying'
          items={value?.thingsAvoidedSaying ?? []}
          type='thingsAvoidedSaying'
          onChange={(items) =>
            onChange({ ...value, thingsAvoidedSaying: items })
          }
        />
      </div>
    </div>
  );
}

export function GenerateRubricButton(props: {
  blockId: string;
  question: string;
  goldenAnswer: string;
  rubric: ModelsScenarioRubric | null;
  onGenerate: (rubric: ModelsScenarioRubric) => void;
}) {
  const { blockId, question, goldenAnswer, rubric, onGenerate } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const {
    call: generateRubric,
    state: {
      state: { isRunning: isGeneratingRubric },
    },
  } = useLiveAsyncCall(async () => {
    if (rubric && rubric.thingsSaidCorrectly.length > 0) {
      const result = await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>
              Are you sure you want to regenerate your evaluation rubric?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
              This will permanently delete any existing content in your rubric.
            </ConfirmCancelModalText>
          </div>
        ),
        confirmBtnLabel: 'Regenerate',
        confirmBtnVariant: 'delete',
        cancelBtnLabel: 'Cancel',
      });
      if (result.result !== 'confirmed') {
        return;
      }
    }

    const resp = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'scenario/generate-rubric',
      variables: {
        question: question,
        goldenAnswer: goldenAnswer,
      },
      metadata: {
        blockId,
      },
    });
    const toolCall = resp.data.toolCalls.at(0);
    if (!toolCall) {
      return;
    }

    const schema = z.object({
      thingsSaidCorrectly: z.array(
        z.object({
          title: z.string(),
          description: z.string(),
        })
      ),
    });
    const output = schema.parse(toolCall.args);
    const thingsSaidCorrectly = output.thingsSaidCorrectly.map((thing) => ({
      ...thing,
      id: uuidv4(),
    }));

    const newRubric = rubric
      ? { ...rubric }
      : {
          thingsSaidCorrectly: [],
          thingsAvoidedSaying: [],
        };
    newRubric.thingsSaidCorrectly = thingsSaidCorrectly;
    onGenerate(newRubric);
  });

  return (
    <>
      <button
        type='button'
        className='w-60 h-10 btn-primary rounded-lg'
        disabled={!question || !goldenAnswer}
        onClick={generateRubric}
      >
        Generate Evaluation
      </button>

      {isGeneratingRubric && (
        <div className='fixed inset-0 bg-lp-black-004 z-50 flex flex-col items-center justify-center'>
          <Loading text='' />
          <div className='mt-2 text-center'>
            <p className='text-white text-lg font-bold'>
              Generating Evaluation Rubric
            </p>
            <p className='mt-1'>This may take a while. Please standby.</p>
          </div>
        </div>
      )}
    </>
  );
}
