import { nanoid } from '@reduxjs/toolkit';
import {
  type Editor,
  type KeyboardShortcutCommand,
  type NodeViewProps,
} from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';

import { DialogueUtils } from './utils';

export const dialogueEditorMarkTriggerShortcuts = (
  editor: Editor
): Record<string, KeyboardShortcutCommand> => {
  const createTriggerMark = () => {
    return editor
      .chain()
      .command(({ state, tr }) => {
        const type = editor.schema.nodes.mark;
        const { $from } = tr.selection;

        if (!$from.parent.canReplaceWith($from.index(), $from.index(), type)) {
          return false;
        }

        const preferredTriggers = [...Array(10).keys()].map(
          (i) => `trigger-${i + 1}`
        );

        state.doc.descendants((node) => {
          if (node.type.name === 'mark' && node.attrs.type === 'trigger') {
            const index = preferredTriggers.indexOf(node.attrs.name);
            if (index !== -1) {
              preferredTriggers.splice(index, 1);
            }
          }
        });

        const name = preferredTriggers.shift() ?? `trigger-${nanoid(4)}`;

        tr.replaceSelectionWith(
          type.create({
            type: 'trigger',
            name,
          })
        );
        return true;
      })
      .run();
  };

  return {
    'Ctrl-Space': createTriggerMark,
    'Ctrl-Shift-T': createTriggerMark,
  };
};

export function DialogueEditorMarkTrigger(props: NodeViewProps) {
  const { name } = props.node.attrs;

  return (
    <NodeViewWrapper
      className='inline-block'
      type='trigger'
      name={name}
      draggable
      data-drag-handle=''
    >
      {name.startsWith('trigger-') ? (
        <span className='trigger-point' title={name}>
          {DialogueUtils.GetTriggerDisplayName(name) ?? '🚩'}
        </span>
      ) : (
        <span className='text-icon-gray font-medium' title={name}>
          [trigger: {name}]
        </span>
      )}
    </NodeViewWrapper>
  );
}
