import {
  type CommonMedia,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
} from '@lp-lib/api-service-client/public';
import { type SlideBlock } from '@lp-lib/game';

import { fromMediaDTO } from '../../../../../utils/api-dto';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../../utils/media';
import { useOrgBrandColor } from '../../../../VenueOrgLogoAverageColor/useOrgBrandColor';
import { DialogueMediaPreview } from '../../../../VoiceOver/Dialogue/DialogueEditor';
import { usePersonality } from '../../../../VoiceOver/usePersonalities';
import { VoiceOverUtils } from '../../../../VoiceOver/utils';
import { SlideBlockAnimation } from './SlideBlockAnimation';

const Animate = SlideBlockAnimation;

function FullHostAvatar(props: { block: SlideBlock }) {
  const { block } = props;
  const personalityId = block.fields.dialogue?.entries?.at(0)?.personalityId;
  const personality = usePersonality(personalityId, {
    fallbackToDefault: true,
  });
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );
  const placeholder =
    VoiceOverUtils.IsDialogueRendering(block.fields.dialogue) ||
    VoiceOverUtils.IsDialogueRequiresRender(block.fields.dialogue);

  if (!mediaUrl) return null;
  return (
    <div className={`relative w-full h-full`}>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      {placeholder && (
        <div className='absolute inset-0 rounded-full flex justify-center items-center'>
          <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
        </div>
      )}
    </div>
  );
}

function BrandBackground() {
  const { color: brandColor } = useOrgBrandColor();
  return (
    <div
      className='w-full h-full opacity-20'
      style={{
        backgroundColor: brandColor,
      }}
    />
  );
}

function MediaBackground(props: {
  media?: CommonMedia | null;
  className?: string;
}) {
  const { media, className } = props;
  if (!media) return null;

  const firstFrameUrl = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
    videoThumbnail: 'first',
    priority: ImagePickPriorityHighToLow,
  });
  return (
    <img
      src={firstFrameUrl ?? ''}
      alt=''
      className={`w-full h-full object-cover select-none ${className}`}
    />
  );
}

function HTMLPreview(props: { block: SlideBlock }) {
  const { block } = props;
  if (!block.fields.html) return null;
  return (
    <div className='absolute inset-0'>
      <iframe
        srcDoc={block.fields.html}
        className='w-full h-full'
        title='Iframe'
        sandbox='allow-scripts'
      />
    </div>
  );
}
export function SlideBlockBackground(props: { block: SlideBlock }) {
  const { block } = props;

  switch (block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutHtml:
      return (
        <div className='absolute inset-0'>
          <HTMLPreview block={block} />
        </div>
      );
    case EnumsSlideLayout.SlideLayoutSlideshow:
      return (
        <div className='absolute inset-0'>
          <DialogueMediaPreview />
        </div>
      );
    case EnumsSlideLayout.SlideLayoutFullHost:
      return (
        <div className='absolute inset-0'>
          <FullHostAvatar block={block} />
        </div>
      );
    case EnumsSlideLayout.SlideLayoutBigMedia:
      return (
        <div className='absolute inset-0'>
          <MediaBackground
            media={block.fields.media?.media}
            className='blur opacity-50'
          />
        </div>
      );
    case EnumsSlideLayout.SlideLayoutIframe:
      if (!block.fields.iframeUrl) return null;
      return (
        <div className='absolute inset-0'>
          <iframe
            src={block.fields.iframeUrl}
            className='w-full h-full'
            title='Iframe'
          />
        </div>
      );
  }

  switch (block.fields.backgroundOption) {
    case EnumsSlideBackgroundOption.SlideBackgroundOptionHost:
      return (
        <div className='absolute inset-0'>
          <FullHostAvatar block={block} />
          <Animate
            name='overlay'
            noInit
            className={`absolute inset-0 bg-black/60`}
          />
        </div>
      );
    case EnumsSlideBackgroundOption.SlideBackgroundOptionBrand:
      return (
        <div className='absolute inset-0'>
          <BrandBackground />
        </div>
      );
    case EnumsSlideBackgroundOption.SlideBackgroundOptionMedia: {
      return (
        <div className='absolute inset-0'>
          <MediaBackground media={block.fields.backgroundMedia?.media} />
        </div>
      );
    }
    case EnumsSlideBackgroundOption.SlideBackgroundOptionMediaWithOverlay: {
      return (
        <div className='absolute inset-0'>
          <MediaBackground media={block.fields.backgroundMedia?.media} />
          <Animate
            name='overlay'
            noInit
            className={`absolute inset-0 bg-black/60`}
          />
        </div>
      );
    }
    default:
      return null;
  }
}
